import React from "react";
export const VideoBg = (props: any) => {
  const { type = "fixed" } = props;
  const isMobile = window?.screen?.width < 600;
  return (
    <div className={`${type}`}>
      {/* bg */}
      <div id="bg" className={`top-0 left-0 z-0 w-[100%] h-[100%]  ${type}`}>
        <video
          autoPlay
          muted
          loop
          playsInline
          className="w-full h-full object-cover"
        >
          <source
            src={`/assets/${isMobile ? "BG_Mobile.mp4" : "BG_01.mp4"}`}
            type="video/mp4"
          />
        </video>
      </div>

      {/* Opacity */}
      <div className="opacity z-10" />
    </div>
  );
};
