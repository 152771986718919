import React, { useState } from "react";
import { ImageOverlay, MapContainer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Land } from "./land";
import { MapEvent } from "./map-event";
import { LandInfo } from "./land-info";
import { LandDetail } from "./land-detail";
import { SelectedLand } from "./selected-land";
import { ViewLandModal } from "./view-land-modal";
import { message } from "antd";
import { generateLandImg } from "./land-image";
import { ComingSoonModal } from "../coming-soon-modal";
import { useMapStore } from "src/stores";

const MAX_GRID = 396;
const MULTIPLIER = 1;

export const LeafMap = (props: any) => {
  const { lands, setLands } = props;
  const [showMap, setShowMap] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(2);
  const [showLandInfo, setShowLandInfo] = useState(false);
  const [showLandDetail, setShowLandDetail] = useState(false);
  const [showViewLand, setShowViewLand] = useState(false);
  const [openComingSoonModal, setOpenComingSoonModal] = useState(false);
  const [landImg, setLandImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [land, setLand] = useState<any>({});

  const { updateLand } = useMapStore();
  const [messageApi, contextHolder] = message.useMessage();
  const defaultPosition: any = [0, 0];

  const getImage = async () => {
    const { x, y, type } = land;
    if (landImg) return;
    setLoading(true);
    let dataUrl: any = await generateLandImg(
      { x: parseInt(x), y: parseInt(y) },
      parseInt(type),
      lands
    );
    setLoading(false);
    setLandImg(dataUrl);
    await updateLand({
      ...land,
      land: land,
      featuredImg: dataUrl,
    });
  };

  return (
    <div className="relative">
      {contextHolder}
      <MapContainer
        style={{ background: "#000" }}
        center={defaultPosition}
        zoom={zoomLevel}
        className="map-container z-[100]"
        zoomDelta={1}
        trackResize={false}
        crs={L.CRS.Simple}
        minZoom={1}
        maxZoom={8}
        maxBounds={[
          [-MAX_GRID / MULTIPLIER, -MAX_GRID / MULTIPLIER],
          [MAX_GRID / MULTIPLIER, MAX_GRID / MULTIPLIER],
        ]}
        preferCanvas={true}
      >
        {/* Selected Land */}
        {land["type"] && (
          <div className="z-[1000]">
            <SelectedLand selectedLand={land} zoomLevel={zoomLevel} />
          </div>
        )}

        {/* Render all lands */}
        {lands.map((item: any, i: number) => {
          return (
            <Land
              land={item}
              key={i}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
              setShowMap={setShowMap}
              showMap={showMap}
              setShowLandDetail={setShowLandDetail}
              setLand={setLand}
              selectedLand={land}
              loading={loading}
              messageApi={messageApi}
              setLandImg={setLandImg}
            />
          );
        })}

        <MapEvent setZoomLevel={setZoomLevel} setShowMap={setShowMap} />

        {/* Background */}
        <ImageOverlay
          bounds={[
            [-MAX_GRID, -MAX_GRID],
            [MAX_GRID, MAX_GRID],
          ]}
          url="/assets/BG_Map_3840.jpg"
        />

        {/* Land Button */}
        <div className="absolute bottom-[90px] sm:bottom-[30px] left-[130px] flex flex-row z-[1001]">
          <div
            className="w-[40px] h-[40px] bg-black border-[1px] border-white rounded-[10px] cursor-pointer"
            onClick={() => setShowLandInfo(true)}
          >
            <img
              src="/assets/btn_19_Land.svg"
              className="w-full h-full p-[5px]"
              alt="land-icon"
            />
          </div>
        </div>
      </MapContainer>

      {/* Land Info */}
      {showLandInfo && <LandInfo setShowLandInfo={setShowLandInfo} />}

      {/* Land Detail */}
      {showLandDetail && (
        <LandDetail
          setShowLandDetail={setShowLandDetail}
          land={land}
          lands={lands}
          setLand={setLand}
          setLands={setLands}
          setShowViewLand={setShowViewLand}
          messageApi={messageApi}
          landImg={landImg}
          setLandImg={setLandImg}
          loading={loading}
          setLoading={setLoading}
          getImage={getImage}
          setOpenComingSoonModal={setOpenComingSoonModal}
        />
      )}

      {/* View Land Coors */}
      {showViewLand && (
        <ViewLandModal
          isModalOpen={showViewLand}
          setIsModalOpen={setShowViewLand}
          land={land}
          messageApi={messageApi}
        />
      )}

      {/* Coming Soon Modal */}
      {openComingSoonModal && (
        <ComingSoonModal
          isModalOpen={openComingSoonModal}
          setIsModalOpen={setOpenComingSoonModal}
        />
      )}
    </div>
  );
};
