import { useWeb3Modal } from "@web3modal/react";
import { useCallback, useEffect, useState } from "react";
import { Container, FullPageLoading } from "src/components";
import { useAccount } from "wagmi";
import { MyPoints } from "./my-points";
import { useUserStore } from "src/stores";
import { Games } from "./games";
import { Shop } from "./shop";
import { Records } from "./records";
import { Leaderboard } from "./leaderboard";

export const Game = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null); // Default: null
  const [tab, setTab] = useState<string>("games");

  const { getProfile } = useUserStore();
  const { address } = useAccount();
  const { open } = useWeb3Modal();

  const getProfileCb = useCallback(async () => {
    const result = await getProfile(`?address=${address}`);
    if (!result) return;
    setUser(result);
  }, [address, getProfile]);

  useEffect(() => {
    if (!address) return;
    getProfileCb();
  }, [address, getProfileCb]);

  useEffect(() => {
    if (!address) return setLoading(false);
    if (address) {
      setLoading(true);
      if (!user) return;
      setLoading(false);
    }
  }, [address, user]);

  return (
    <Container containerStyle="bg-gradient-to-b from-[#DEDEDE] to-[#A2A2A2]">
      {loading && <FullPageLoading transparentBg={true} />}

      {/* If no address */}
      {!address && (
        <div className="w-full justify-center items-center flex px-[30px] pb-[80px] h-screen overflow-y-auto">
          <button
            className="text !py-[10px] !px-[50px] hover:!border-[#000] hover:shadow-2xl hover:shadow-[#fff]"
            onClick={() => open()}
          >
            Connect Wallet
          </button>
        </div>
      )}

      {!loading && address && (
        <div className="pt-28 w-full justify-center items-start flex px-[30px] pb-[80px] h-screen overflow-y-auto">
          <div className="max-w-[1200px] mx-auto w-full">
            <MyPoints user={user} setTab={setTab} tab={tab} />

            {/* Page Content */}
            {tab === "games" && <Games />}
            {tab === "shop" && <Shop />}
            {tab === "records" && <Records address={address} />}
            {tab === "leaderboard" && <Leaderboard />}
          </div>
        </div>
      )}
    </Container>
  );
};
