import { history } from "src/stores";

export const GameBlock = (props: any) => {
  const { game } = props;
  const {
    pair: gameName = "BTC",
    duration = 30,
    identifier,
    bet,
    start_price = 0,
  } = game;

  const getDuration = (duration: number) => {
    let units = "mins";
    let formtattedDuration = duration * 2;
    if (formtattedDuration >= 60) {
      units = "hour";
      if (formtattedDuration > 60) units = "hours";
      formtattedDuration = formtattedDuration / 60;
    }

    return `${formtattedDuration} ${units}`;
  };

  return (
    <div
      className="mr-[0px] md:mr-[30px] mb-[30px] py-[15px] px-[20px] min-w-auto md:min-w-[350px] bg-white rounded-[20px] min-h-[100px] transition-all duration-300 cursor-pointer drop-shadow-none hover:drop-shadow-md hover:drop-shadow-primary"
      onClick={() => history.push(`/game/${game._id}`)}
    >
      <p className="text-black max-w-[150px]">{identifier}</p>

      <div className="flex flex-row items-start pt-[10px]">
        <h2 className="text-black flex-1">{gameName}</h2>
        <p className="text-slate-500 text-[12px]">{getDuration(duration)}</p>
      </div>

      <div className="flex flex-row md:flex-row pt-[20px]">
        <div className="flex flex-col">
          <p className={$label}>Each Prediction</p>
          <h2 className={`${$lgLabel}`}>{bet} GC</h2>
        </div>

        <div className="flex flex-col ml-[50px]">
          <p className={$label}>Start Price</p>
          <h2 className={` ${$lgLabel}`}>
            {Number(start_price).toFixed(4)} {gameName}
          </h2>
        </div>
      </div>
    </div>
  );
};

const $lgLabel =
  "text-[16px] leading-[20px] mb-[0px] mt-[5px] text-black font-[500]";
const $label = "text-black text-[12px] font-[300";
