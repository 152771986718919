import { Modal } from "antd";
import React from "react";
import { ReactComponent as Profile } from "../button/icons/btn_06_profile.svg";
import { ReactComponent as Map } from "../button/icons/btn_02_map.svg";
import { ReactComponent as MetaverseIcon } from "../button/icons/btn_01_metaverse.svg";
import { ReactComponent as OpenseaIcon } from "../button/icons/btn_04_opensea.svg";
import { ReactComponent as TrailerIcon } from "../button/icons/btn_03_trailer.svg";
import { ReactComponent as MooarIcon } from "../button/icons/btn_33_mooar.svg";
import { ReactComponent as AirdropIcon } from "../button/icons/btn_35_airdrop.svg";
import { history } from "src/stores";
import { CloseButton } from "../close-btn";
// import { mataverseHost } from "src/config";

const menuItems = "flex flex-row menu-item cursor-pointer items-center";
const footerTxt =
  "ml-[20px] text-[15px] uppercase cursor-pointer duration-300 transition-all hover:!text-[#c8c8c8]";
export const DesktopMenuModal = (props: any) => {
  const {
    isModalOpen,
    setIsModalOpen,
    setShowTrailer,
    // setShowComingSoonModal,
  } = props;
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const redirect = (
    url: string,
    type: "url" | "page" | "trailer",
    self?: boolean
  ) => {
    if (type === "url") window.open(url, self ? "_self" : "_blank");
    if (type === "page") history.push(url);
    if (type === "trailer") {
      setShowTrailer(true);
    }
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={null}
      open={isModalOpen}
      onOk={() => null}
      onCancel={handleCancel}
      footer={null}
      wrapClassName={"desktop-modal"}
    >
      <div className="py-[40px] pl-[0px] sm:pl-[20%]">
        <div
          className={menuItems}
          onClick={() => redirect("/land-licenses", "page", false)}
        >
          <Profile />
          <p>Profile</p>
        </div>
        <div
          className={menuItems}
          onClick={() => redirect("/airdrop", "page", false)}
        >
          <AirdropIcon />
          <p>Airdrop</p>
        </div>
        <div
          className={menuItems}
          onClick={() => redirect("/map", "page", false)}
        >
          <Map />
          <p>Map</p>
        </div>
        <div
          className={`${menuItems}`}
          onClick={() => redirect("/game", "page", false)}
          // onClick={() =>
          //   mataverseHost
          //     ? window.open(mataverseHost)
          //     : setShowComingSoonModal(true)
          // }
        >
          <MetaverseIcon />
          <p>Game</p>
        </div>
        <div
          className={menuItems}
          onClick={() =>
            redirect(
              "https://opensea.io/collection/gigaspace-2140",
              "url",
              false
            )
          }
        >
          <OpenseaIcon />
          <p>Opensea</p>
        </div>
        <div
          className={menuItems}
          onClick={() =>
            redirect(
              "https://www.mooar.com/collection/0xE04162cdeCC532F6840a3066a0664F91DbC0Ea3b",
              "url",
              false
            )
          }
        >
          <MooarIcon />
          <p>Mooar</p>
        </div>
        <div className={menuItems} onClick={() => redirect("", "trailer")}>
          <TrailerIcon />
          <p>Trailer</p>
        </div>
      </div>

      <div className="flex flex-row absolute top-[30px] right-[30px] items-center">
        <p onClick={handleCancel} className="close-menu-txt mr-[10px]">
          Close Menu
        </p>
        {/* Close btn */}
        <CloseButton
          handleCancel={handleCancel}
          type="black"
          position="relative"
        />
      </div>

      <div className="flex flex-col sm:flex-row text-right sm:text-left absolute bottom-[30px] right-[30px]">
        <p
          className={footerTxt}
          onClick={() => redirect("https://discord.gg/gigaspace", "url", false)}
        >
          Join our discord
        </p>
        <p
          className={footerTxt}
          onClick={() =>
            redirect("https://twitter.com/Gigaspace2140", "url", false)
          }
        >
          Follow us on Twitter
        </p>
      </div>
    </Modal>
  );
};
