export const SelectNft = (props: any) => {
  const tabs = ["all", "land", "gigi"];
  const { tab, setTab } = props;
  return (
    <div className="flex flex-row items-center mt-[15px]">
      {tabs.map((item, i) => {
        return (
          <div className="i" key={i}>
            <p
              className="uppercase text-[15px] mr-[15px] hover:!text-[#d8d8d8] duration-300 transition-all cursor-pointer"
              style={{ color: tab === item ? "#fff" : "#505050" }}
              onClick={() => setTab(item)}
            >
              {item}
            </p>
          </div>
        );
      })}
    </div>
  );
};
