import moment from "moment";
import { Countdown } from "./countdown";
import { MoonAnimation } from "./moon-animation";
import { BetBtns } from "./bet-btns";
import { history } from "src/stores";

export const FeaturedGame = (props: any) => {
  const { game, refresh = null, back = false } = props;
  const {
    pair: gameName = "BTC",
    duration = 30,
    identifier = "btc-24h",
    bet,
    prepare_end_at = new Date(),
    game_end_at = new Date(),
    start_price = 0,
    end_price = 0,
    status,
  } = game || {};

  const backToGame = () => {
    if (!back) return;
    history.push("/game");
  };

  const getColor = (price: number) => {
    if (price < 0) return "#f152ff";
    if (price > 0) return "rgb(34, 197, 94)";
    return "#fff";
  };

  const getDuration = (duration: number) => {
    let units = "mins";
    let formtattedDuration = duration * 2;
    if (formtattedDuration >= 60) {
      units = "hour";
      if (formtattedDuration > 60) units = "hours";
      formtattedDuration = formtattedDuration / 60;
    }

    return `${formtattedDuration} ${units}`;
  };

  return (
    <div className="flex flex-col 2xl:flex-row items-start justify-between ">
      <div className="flex-1">
        <div
          className="flex flex-row items-center cursor-pointer"
          onClick={backToGame}
        >
          {back && (
            <img
              src="/assets/back.png"
              alt="back"
              className={"w-[20px] mr-[10px]"}
            />
          )}
          <p className="max-w-[150px] text-[12px]">{identifier}</p>
        </div>
        <h1 className="max-w-[650px] text-[32px] leading-[40px] md:text-[48px] md:leading-[60px] font-[900] pt-[10px]">
          Will {gameName} go <br></br>UP or DOWN<br></br>in the next{" "}
          {getDuration(duration)}?
        </h1>

        {/* Each Stake */}
        <div className="flex flex-col md:flex-row pt-[20px]">
          <div className="flex flex-col">
            <p>Each Prediction</p>
            <h2 className={`txt-gradient ${$lgLabel}`}>{bet} GC</h2>
          </div>

          <div className="flex flex-col mt-[20px] md:mt-[0px] ml-[0px] md:ml-[50px]">
            <p>Start Price</p>
            <h2 className={`txt-gradient ${$lgLabel}`}>
              {Number(start_price).toFixed(4)} {gameName}
            </h2>
          </div>

          {status === "accept_bet" && (
            <div className="flex flex-col mt-[20px] md:mt-[0px] ml-[0px] md:ml-[50px]">
              <p>Prediction Time Left</p>
              <h2 className={`txt-black ${$lgLabel}`}>
                <Countdown
                  datetime={moment.utc(prepare_end_at).local()}
                  refresh={refresh}
                />
              </h2>
            </div>
          )}

          {status === "game_starting" && (
            <div className="flex flex-col mt-[20px] md:mt-[0px] ml-[0px] md:ml-[50px]">
              <p>Payout Time</p>
              <h2 className={`txt-black ${$lgLabel}`}>
                <Countdown
                  datetime={moment.utc(game_end_at).local()}
                  refresh={refresh}
                />
              </h2>
            </div>
          )}

          {status === "end" && (
            <div className="flex flex-col mt-[20px] md:mt-[0px] ml-[0px] md:ml-[50px]">
              <p>End Price</p>
              <h2
                className={`${$lgLabel}`}
                style={{
                  color: getColor(end_price - start_price),
                }}
              >
                {end_price.toFixed(2)} {gameName}
              </h2>
            </div>
          )}
        </div>

        {/* Bet Buttons */}
        {status === "accept_bet" && (
          <div className="mt-[30px]">
            <BetBtns game={game} refresh={refresh} />
          </div>
        )}

        {/* Notes */}
        <p className="mt-[30px] text-[10px]">
          Payout time at{" "}
          {moment.utc(game_end_at).local().format("YYYY-MM-DD HH:mm:ss")}
        </p>
      </div>

      <div
        className="ml-[0px] 2xl:ml-[50px] mt-[50px] 2xl:mt-[0px] p-[50px] 2xl:p-[100px] rounded-[30px] flex justify-center items-center bg-cover"
        style={{ backgroundImage: "url('/assets/game-asset-bg.jpg')" }}
      >
        <MoonAnimation />
      </div>
    </div>
  );
};

const $lgLabel = "text-[30px] leading-[30px] mb-[0px] mt-[5px]";
