import { createContext, useContext } from "react";
import { MapStore, STORE_MAP } from "./map";
import { STORE_ROUTER, RouterStore, history } from "./router";
import { AirdropStore, STORE_AIRDROP } from "./airdrop";
import { GameStore, STORE_GAME } from "./game";
import { STORE_USER, UserStore } from "./user";

function createStores() {
  return {
    [STORE_ROUTER]: new RouterStore(),
    [STORE_AIRDROP]: new AirdropStore(),
    [STORE_MAP]: new MapStore(),
    [STORE_GAME]: new GameStore(),
    [STORE_USER]: new UserStore(),
  };
}

const stores = createStores();

const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

function useRouterStore() {
  const { routerStore } = useStores();
  return routerStore;
}

export function useAirdropStore() {
  const { airdropStore } = useStores();
  return airdropStore;
}

export function useMapStore() {
  const { mapStore } = useStores();
  return mapStore;
}

export function useGameStore() {
  const { gameStore } = useStores();
  return gameStore;
}

export function useUserStore() {
  const { userStore } = useStores();
  return userStore;
}

const generalError = { success: false, error: "general_error" };

export { stores, StoresContext, generalError, history, useRouterStore };
