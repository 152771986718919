/* eslint-disable */
import { action } from "mobx";
import * as Configs from "../config";
import axios from "axios";

export class MapStore {
  @action.bound
  async confirmPurchase(data: any) {
    const url = `${Configs.API_URL}/confirm-purchase`;
    try {
      let result: any = await axios.post(url, data);
      if (!result || result.status !== 200) return false;
      return true;
    } catch (err) {
      return false;
    }
  }

  @action.bound
  async getAllLands() {
    const url = `${Configs.API_URL}/lands/get-lands`;
    try {
      let result: any = await axios.get(url);
      if (!result || result.status !== 200) return null;
      return result.data.data;
    } catch (err) {
      return false;
    }
  }

  @action.bound
  async getLand(x: number, y: number) {
    const url = `${Configs.API_URL}/get-land?x=${x}&y=${y}`;
    try {
      let result: any = await axios.get(url);
      if (!result || result.status !== 200) return null;
      return result.data.data.land;
    } catch (err) {
      return false;
    }
  }

  @action.bound
  async getLands(args: any = null) {
    const url = `${Configs.API_URL}/get-lands?${args}`;
    try {
      let result: any = await axios.get(url);
      if (!result || result.status !== 200) return [];
      return result.data.data.lands;
    } catch (err) {
      return [];
    }
  }

  @action.bound
  async getSaleLands() {
    const url = `${Configs.API_URL}/get-sale-lands`;
    try {
      let result: any = await axios.get(url);
      if (!result || result.status !== 200) return [];
      return result.data.data.saleLands;
    } catch (err) {
      return [];
    }
  }

  @action.bound
  async getSaleLandsStroke() {
    const url = `${Configs.API_URL}/get-sale-lands-stroke`;
    try {
      let result: any = await axios.get(url);
      if (!result || result.status !== 200) return [];
      return result.data.data.stroke.sort((a: any, b: any) => a.id - b.id);
    } catch (err) {
      return [];
    }
  }

  @action.bound
  async getToken(
    address: string | null | undefined,
    x: number,
    y: number,
    type: number
  ) {
    const url = `${Configs.API_URL}/get-token?address=${address}&x=${x}&y=${y}&type=${type}`;
    try {
      let result: any = await axios.get(url);
      if (!result || result.status !== 200) return null;
      return result.data.data.token;
    } catch (err) {
      return false;
    }
  }

  @action.bound
  async pinToIpfs(data: any) {
    const url = `${Configs.API_URL}/pin-to-ipfs`;
    try {
      let result: any = await axios.post(url, data);
      if (!result || result.status !== 200) return false;
      return result.data.data.ipfsHash;
    } catch (err) {
      return false;
    }
  }

  @action.bound
  async updateLand(payload: any) {
    const url = `${Configs.API_URL}/update-land`;
    try {
      let result: any = await axios.put(url, payload);
      if (!result || result.status !== 200) return false;
      return true;
    } catch (err) {
      return false;
    }
  }
}

export const STORE_MAP = "mapStore";
