import * as React from "react";
import { Router, Switch, Route, Redirect } from "react-router";
import { history } from "./stores";

// Components
import { Home } from "./pages/home";
import { Map } from "./pages/map";
import { LandLicenses } from "./pages/land-licenses";
import { Airdrop } from "./pages/airdrop";
import { Game } from "./pages/game";
import { GameDetail } from "./pages/game-detail";

export default function Routers() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path={"/"}
          exact
          component={(props: any) => <Home {...props} />}
        />
        <Route
          path={"/airdrop"}
          exact
          component={(props: any) => <Airdrop {...props} />}
        />
        <Route
          path={"/game"}
          exact
          component={(props: any) => <Game {...props} />}
        />
        <Route
          path={"/game/:id"}
          exact
          component={(props: any) => <GameDetail {...props} />}
        />
        <Route
          path={"/map"}
          exact
          component={(props: any) => <Map {...props} />}
        />
        <Route
          path={"/land-licenses"}
          exact
          component={(props: any) => <LandLicenses {...props} />}
        />
        <Route path="*">
          <Redirect from={"/"} to={"/"} />
        </Route>
      </Switch>
    </Router>
  );
}
