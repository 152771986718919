import { useWeb3Modal } from "@web3modal/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  eid,
  getTokenDp,
  landPrice,
  masterWhitelist,
  mataverseHost,
  NETWORK,
  openseaUrl,
  tokenContract,
} from "src/config";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import { generateLandImg } from "./land-image";
import { message } from "antd";
import { useMapStore } from "src/stores";
import { SelectTokenModal } from "./select-token-modal";
import { formatUnits, parseUnits } from "ethers/lib/utils.js";

const BOTTOM_MENUS =
  "fixed left-0 px-[30px] lg:px-[0px] lg:left-[auto] bg-black max-w-[auto] lg:max-w-[364px] w-full bottom-[30px] py-5 grid grid-cols-2 gap-[24px]";
const CONTRACT: any = process.env.REACT_APP_CONTRACT;

export const LandBottomButton = (props: any) => {
  const { getLand, getToken, pinToIpfs, confirmPurchase, getAllLands } =
    useMapStore();
  const {
    land = null,
    lands,
    setLands,
    setShowLandDetail,
    setOpenComingSoonModal,
  } = props;
  const { open } = useWeb3Modal();
  const [messageApi, contextHolder] = message.useMessage();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  // Use States
  const [minting, setMinting] = useState<boolean>(false);
  const [openMintModal, setOpenMintModal] = useState<boolean>(false);
  const [selectedToken, setSelectedToken] = useState<string>("eth");
  const [ipfs, setIpfs] = useState<string | null>(null);
  const [tokenId, setTokenId] = useState<string | null>(null);
  const [finalPrice, setFinalPrice] = useState<any>(null);
  const [startMint, setStartMint] = useState(false);
  const [startApprove, setStartApprove] = useState(false);
  const [hash, setHash] = useState<`0x${string}` | undefined>(undefined);

  // Use Memo
  const eidVal = useMemo(() => {
    if (selectedToken === "eth") return 0;
    return eid[selectedToken][String(land["type"])];
  }, [selectedToken, land]);

  // Wagmi Hooks
  const { data: balance } = useContractRead({
    address: tokenContract[selectedToken],
    functionName: "balanceOf",
    abi: require("../../config/erc20.json"),
    args: [address],
  });
  const { data: allowance } = useContractRead({
    address: tokenContract[selectedToken],
    functionName: "allowance",
    abi: require("../../config/erc20.json"),
    args: [address, process.env.REACT_APP_CONTRACT],
  });
  const { write } = useContractWrite({
    address: CONTRACT,
    abi: require("../../config/abi.json"),
    functionName: "privateMint",
    args: [address, land["type"], land["x"], land["y"], ipfs, tokenId],
    onSuccess: (data: any) => {
      if (data.hash) setHash(data.hash);
      setMinting(false);
      messageApi.warning(`Waiting for the transaction...`);
    },
    onError: (error: any) => minErr(error),
  });
  const { write: privateMintERC20 } = useContractWrite({
    address: CONTRACT,
    abi: require("../../config/abi.json"),
    functionName: "privateMintERC20",
    args: [address, land["type"], land["x"], land["y"], ipfs, eidVal, tokenId],
    onSuccess: (data: any) => {
      if (data.hash) setHash(data.hash);
      setMinting(false);
      messageApi.warning(`Waiting for the transaction...`);
    },
    onError: (error: any) => minErr(error),
  });
  const { write: approve } = useContractWrite({
    address: tokenContract[selectedToken],
    abi: require("../../config/erc20.json"),
    functionName: "approve",
    args: [process.env.REACT_APP_CONTRACT, finalPrice],
    onSuccess: (data: any) => {
      privateMintERC20?.();
    },
    onError: (error: any) => {
      let message = "approve failed at the moment";
      messageApi.error(`Sorry ${message}, please try again later`);
      setMinting(false);
    },
  });
  const { isLoading } = useWaitForTransaction({
    hash: hash || undefined,
    onSuccess: async (data) => {
      setOpenMintModal(false);
      messageApi.success(
        "Successfully minted! We are now transferring the land data to you..."
      );
      const { type } = land;
      let dataUrl = await generateLandImg(land, type, lands);
      let result = await confirmPurchase({
        address: address,
        x: land["x"],
        y: land["y"],
        type,
        dataUrl,
      });
      if (!result)
        return messageApi.warning(
          `Transferred land data failed, please wait a second`
        );
      messageApi.success("You are the new land owner!");
      setShowLandDetail(false);
      getAllLands().then((result) => {
        if (!result) return;
        setLands(result);
      });
    },
    onError: (error) => {
      messageApi.error(`Failed to send tranaction, please try again later`);
      setMinting(false);
    },
  });

  // useEffects
  useEffect(() => {
    if (!startMint) return;
    setStartMint((state) => {
      write &&
        write({
          value: finalPrice,
        });
      return false;
    });
  }, [startMint, write, finalPrice]);

  useEffect(() => {
    if (!startApprove) return;
    setStartApprove((state) => {
      approve && approve();
      return false;
    });
  }, [startApprove, approve]);

  const minErr = (error: any) => {
    let message = "mint failed at the moment";
    let code = error.code || "";
    switch (code) {
      case "UNPREDICTABLE_GAS_LIMIT":
        message = "gas limit cannot be calculated";
        break;
      default:
        break;
    }
    messageApi.error(`Sorry ${message}, please try again later`);
    setMinting(false);
  };

  const getPrice = (token: string = "eth") => {
    if (!address) return 0;
    if (masterWhitelist.indexOf(address) > -1) return 0;
    return landPrice[land["type"]][token] || 0;
  };

  const mint = async () => {
    // Check network
    if (NETWORK === "mainnet" && chain?.id !== 1)
      switchNetwork && switchNetwork(1);
    if (NETWORK === "testnet" && chain?.id !== 5)
      switchNetwork && switchNetwork(5);

    if (minting)
      return messageApi.warning(`Another minting action in process...`);
    messageApi.warning(`Starting to buy land...`);
    setMinting(true);
    const { x, y, type } = land;

    // Check Land
    let isLandPurchased = await getLand(x, y);
    if (isLandPurchased) {
      messageApi.warning("This land is purchased.");
      return setMinting(false);
    }

    // Get token
    let token = await getToken(address, x, y, type);
    if (!token) {
      messageApi.warning(`You're not allowed to mint, please try again later.`);
      return setMinting(false);
    }
    setTokenId(token);

    // Pin to ipfs
    let ipfsHash = await pinToIpfs({ x, y, type });
    setIpfs(ipfsHash);

    // Mint - ETH
    let price = getPrice(selectedToken);
    let bigNumPrice: any = parseUnits(String(price), getTokenDp(selectedToken));
    if (!bigNumPrice) {
      messageApi.warning(`Cannot calculate the price, please try again later.`);
      return setMinting(false);
    }
    setFinalPrice(bigNumPrice);

    if (selectedToken === "eth") {
      try {
        setStartMint(true);
        // write && write();
      } catch (e) {
        messageApi.warning(`Failed to mint, please try again later.`);
        return setMinting(false);
      }
    }

    if (selectedToken !== "eth") {
      if (!balance || !allowance) {
        messageApi.warning(
          `Failed to get balance or allowance, please try again later.`
        );
        return setMinting(false);
      }
      // Format Balance
      let bigNumBalance: any = balance;
      let bigNumAllowance: any = allowance;
      const formattedBalance = formatUnits(
        bigNumBalance,
        getTokenDp(selectedToken)
      );
      const formattedAllowance = formatUnits(
        bigNumAllowance,
        getTokenDp(selectedToken)
      );
      // Check Balance
      if (parseFloat(formattedBalance) < price) {
        messageApi.warning("Sorry, your token balance is not enough");
        return setMinting(false);
      }
      if (parseFloat(formattedAllowance) < price) {
        messageApi.warning(
          `Please first allow GigaSpace to use your ${selectedToken.toUpperCase()}`
        );
      }
      setStartApprove(true);
    }
    setMinting(false);
  };

  const renderJumpInBtn = () => {
    return (
      <button
        className="text w-full"
        onClick={() =>
          mataverseHost
            ? window.open(`${mataverseHost}?land=${land["x"]},${land["y"]}`)
            : setOpenComingSoonModal(true)
        }
      >
        Jump In
      </button>
    );
  };

  if (land.tokenId) {
    return (
      <div className={BOTTOM_MENUS}>
        <button
          className="text w-full"
          onClick={() => {
            window.open(
              `${openseaUrl}/${land.tokenId}`,
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          Opensea
        </button>
        {renderJumpInBtn()}
      </div>
    );
  }
  if (!address) {
    return (
      <div className={BOTTOM_MENUS}>
        <button className="text w-full" onClick={() => open()}>
          Connect
        </button>
        {renderJumpInBtn()}
      </div>
    );
  }

  if (address) {
    const AVAILABLE_LAND_SIZE = masterWhitelist.indexOf(address) > -1 ? 24 : 3;
    if (!AVAILABLE_LAND_SIZE) return null;
    const availableToPurchase =
      masterWhitelist.indexOf(address) > -1 ? true : land.in_sale;
    if (!availableToPurchase) return null;

    return (
      <>
        <div className={BOTTOM_MENUS}>
          {contextHolder}
          <button
            className="text w-full"
            onClick={() => setOpenMintModal(true)}
          >
            Buy Now
          </button>
          {renderJumpInBtn()}

          <SelectTokenModal
            mint={mint}
            isModalOpen={openMintModal}
            setIsModalOpen={setOpenMintModal}
            land={land}
            address={address}
            selectedToken={selectedToken}
            setSelectedToken={setSelectedToken}
            getPrice={getPrice}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  }

  return null;
};
