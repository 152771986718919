import React from "react";
import { getLandTypeName } from "src/utils";
import { LandBottomButton } from "./land-bottom-button";
import { LandlCarousel } from "./land-carousel";

export const LandDetail = (props: any) => {
  const {
    setShowLandDetail,
    land = {},
    lands = [],
    setLands,
    setLand,
    setShowViewLand,
    messageApi,
    getImage,
    landImg,
    loading,
    setLandImg,
    setOpenComingSoonModal,
  } = props;

  return (
    <div
      className="rounded-[0px] lg:rounded-[10px] max-w-[auto] w-[100%] lg:max-w-[418px] bg-black p-[30px] fixed right-[0px] lg:right-[30px] top-[0px] lg:top-[100px] z-[1002] overflow-hidden !overflow-y-auto pb-[80px] min-h-[100vh] lg:min-h-[auto]"
      style={{ height: `calc(100% - 120px)` }}
    >
      {/* Land Image */}
      <LandlCarousel land={land} landImg={landImg} loading={loading} />

      {/* View Land Button */}
      <button
        className="text w-full mt-[24px]"
        onClick={() => setShowViewLand(true)}
      >
        View Land
      </button>

      {/* Land Information */}
      <div className="mt-[24px] relative">
        <h3 className="tracking-[0px] leading-[20px] font-[500] text-[25px]">
          {land.x1}, {land.y1}
        </h3>
        <p className="uppercase mt-[15px] text-[12px] font-[400]">
          land: {getLandTypeName(land.type)}
        </p>
        <p className="uppercase mt-[15px] text-[12px] font-[400]">
          Size: {land.type} x {land.type} ( {land.type * land.type} parsel
          {land.type !== 1 && `s`} )
        </p>

        {/* Edit button */}
        {land.tokenId && (
          <p className="cursor-pointer uppercase absolute right-[0px] bottom-[0px] text-[14px] text-[#666] duration-300 transition-all hover:text-[#d8d8d8]">
            Edit
          </p>
        )}
      </div>

      {/* More land info */}
      {land.tokenId && (
        <div>
          <div className="my-[15px] w-full block h-[1px] bg-[#666]" />
          <div className="flex flex-row items-center">
            <p className="font-[400] uppercase overflow-hidden text-[12px] text-ellipsis max-w-[200px]">
              {land.owner}
            </p>
            <div
              className="w-[20px] h-[20px] overflow-hidden cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(land.owner);
                messageApi.success("Address is copied!");
              }}
            >
              <img
                src="/assets/icons-white/btn_12_copy.svg"
                alt="copy-icon"
                className="ml-10px w-[28px] h-[28px] relative top-[-4px]"
              />
            </div>
          </div>
          <div className="my-[15px] w-full block h-[1px] bg-[#666]" />
          <p className="uppercase mt-[15px] text-[12px] font-[700]">
            {land.land_name}
          </p>
          <p className="mt-[15px] text-[12px] font-[400]">{land.land_desc}</p>
        </div>
      )}

      {/* Close Button */}
      <div
        className="absolute bg-black rounded-[10px] top-[40px] right-[40px] z-10 w-[30px] h-[30px] cursor-pointer"
        onClick={() => {
          setShowLandDetail(false);
          setLand({});
          setLandImg(null);
        }}
      >
        <img
          src="/assets/icons-white/btn_11_close.svg"
          className="w-full h-full p-[2.5px] object-contain"
          alt="close-btn"
        />
      </div>

      {/* Land Image Button */}
      {land.owner && (
        <div
          className="absolute bg-black rounded-[10px] top-[40px] left-[40px] z-10 w-[30px] h-[30px] cursor-pointer"
          onClick={() => getImage()}
        >
          <img
            src="/assets/icons-white/btn_34_image.svg"
            className="w-full h-full p-[5px] object-contain"
            alt="show-land-btn"
          />
        </div>
      )}

      {/* Fixed bottom menu */}
      <LandBottomButton
        land={land}
        lands={lands}
        setLands={setLands}
        setShowLandDetail={setShowLandDetail}
        setLand={setLand}
        setOpenComingSoonModal={setOpenComingSoonModal}
      />
    </div>
  );
};
