/* eslint-disable */
import { action } from "mobx";
import * as Configs from "../config";
import axios from "axios";

export class AirdropStore {
  @action.bound
  async getAllPoints() {
    const url = `${Configs.API_URL}/airdrop/get-all-points`;
    try {
      let result: any = await axios.get(url);
      if (!result || result.status !== 200) return false;
      return result["data"]["data"];
    } catch (err) {
      return false;
    }
  }

  @action.bound
  async getMyPoints(address: string) {
    const url = `${Configs.API_URL}/airdrop/get-my-points?address=${address}`;
    try {
      let result: any = await axios.get(url);
      if (!result || result.status !== 200) return false;
      return result["data"]["data"];
    } catch (err) {
      return false;
    }
  }
}

export const STORE_AIRDROP = "airdropStore";
