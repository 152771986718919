import axios from "axios";
import * as Configs from "../config";
import { errorHandling, setHeader } from "./api";

export class UserStore {
  async getProfile(query: string = "") {
    const url = `${Configs.API_URL}/v1/user/profile${query}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_USER = "userStore";
