import { useMap, useMapEvents } from "react-leaflet";

export const MapEvent = (props: any) => {
  const { setZoomLevel } = props;

  // Map Events
  const map = useMap();
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });

  map.zoomControl.setPosition("bottomleft");
  return null;
};
