export const SelectPage = (props: any) => {
  const tabs = ["games", "shop", "records", "leaderboard"];
  const { tab, setTab } = props;
  return (
    <div className="flex flex-row items-center mt-[15px]">
      {tabs.map((item, i) => {
        return (
          <div className="i" key={i}>
            <p
              className="uppercase text-[15px] mr-[15px] hover:!text-[#3c3c3c] duration-300 transition-all cursor-pointer"
              style={{ color: tab === item ? "#000" : "#505050" }}
              onClick={() => setTab(item)}
            >
              {item}
            </p>
          </div>
        );
      })}
    </div>
  );
};
