export const formatLand = (
  pos: number,
  type: string,
  LENGTH: number,
  CELL_SIZE: number
) => {
  if (type === "x") {
    return pos + LENGTH / CELL_SIZE / 2;
  }

  if (type === "y") {
    return pos * -1 + LENGTH / CELL_SIZE / 2;
  }

  return 0;
};

export const reFormatLand = (
  pos: number,
  type: string,
  LENGTH: number,
  CELL_SIZE: number
) => {
  if (type === "x") {
    return pos - LENGTH / CELL_SIZE / 2;
  }

  if (type === "y") {
    return (pos - LENGTH / CELL_SIZE / 2) * -1;
  }

  return 0;
};
