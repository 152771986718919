import React from "react";
import { numberCommaFormatted } from "src/utils";

export const Leaderboard = (props: any) => {
  const { address, myPoints, tier = "", myRank = 999, allPoints } = props;
  const {
    total_points: totalPoints = 0,
    points_from_lands: pointsFromLands = 0,
    points_from_gigi: pointsFromGigi = 0,
    points_from_land_trade = 0,
    points_from_gigi_trade = 0,
  } = myPoints || {};

  const getPositionName = () => {
    if (myRank === 0) return "-";
    return String(myRank);
  };

  const getTier = (ranking: number) => {
    switch (true) {
      case ranking > 0 && ranking <= 10:
        return "S";
      case ranking > 10 && ranking <= 50:
        return "A";
      case ranking > 50 && ranking <= 150:
        return "B";
      case ranking > 150 && ranking <= 300:
        return "C";
      case ranking > 300 && ranking <= 500:
        return "D";
      default:
        return "E";
    }
  };

  return (
    <div className="flex flex-col">
      <dl className="w-full flex flex-row justify-between items-center pb-[0px] lg:pb-[0px]">
        <dt className="w-[60px] ml-[10px]">
          <p className={$labelStyle}>Pos</p>
        </dt>
        <dt className="w-[100px] ml-[20px]">
          <p className={`${$labelStyle} !text-left`}>Name</p>
        </dt>
        <dt className="w-[100px]">
          <p className={$labelStyle}>Tier</p>
        </dt>
        <dt className="w-[130px] desktop">
          <p className={$labelStyle}>From Lands</p>
        </dt>
        <dt className="w-[130px] desktop">
          <p className={$labelStyle}>From Gigi</p>
        </dt>
        <dt className="w-[130px] desktop">
          <p className={$labelStyle}>From Trade</p>
        </dt>
        <dt className="w-[130px] mr-[10px]">
          <p className={`${$labelStyle} !text-right`}>Total Pts</p>
        </dt>
      </dl>

      {/* My Row */}
      <div
        className={$rowStyle}
        style={{
          background: `linear-gradient(90deg, rgba(58,243,36,1) 0%, rgba(115,79,204,1) 33%, rgba(69,40,247,1) 66%, rgba(38,230,243,1) 100%)`,
        }}
      >
        <dl
          className={$tableRow}
          style={{
            background: `linear-gradient(90deg, rgba(207,248,201,1) 0%, rgba(214,182,255,1) 33%, rgba(190,195,252,1) 66%, rgba(202,247,251,1) 100%)`,
          }}
        >
          <dd className="w-[60px] ml-[10px]">
            <p
              className={`${$labelStyle} bg-[#000] !text-white h-[30px] rounded-[10px] p-[3px] py-[5px]`}
            >
              {getPositionName()}
            </p>
          </dd>
          <dd className="w-[100px] ml-[20px]">
            <p
              className={`${$labelStyle} !text-left overflow-hidden text-ellipsis w-[70px] lg:w-[100px] `}
            >
              YOU
            </p>
          </dd>
          <dd className="w-[100px]">
            <p className={$labelStyle}>{tier}</p>
          </dd>
          <dd className="w-[130px] desktop">
            <p className={$labelStyle}>
              {numberCommaFormatted(String(pointsFromLands))}
            </p>
          </dd>
          <dd className="w-[130px] desktop">
            <p className={$labelStyle}>
              {numberCommaFormatted(String(pointsFromGigi))}
            </p>
          </dd>
          <dd className="w-[130px] desktop">
            <p className={$labelStyle}>
              {numberCommaFormatted(
                String(points_from_gigi_trade + points_from_land_trade)
              )}
            </p>
          </dd>
          <dd className="w-[130px] mr-[10px]">
            <p className={`${$labelStyle} !text-right`}>
              {numberCommaFormatted(String(totalPoints))}
            </p>
          </dd>
        </dl>
      </div>

      {/* All Other Rows */}
      <div className="overflow-y-scroll h-screen flex flex-col pb-[400px]">
        {allPoints &&
          allPoints.map((item: any, i: number) => {
            return (
              <div
                className={`${$rowStyle} group transition-all duration-300`}
                style={{
                  ...(address.toLowerCase() === item.address.toLowerCase() && {
                    background: `linear-gradient(90deg, rgba(58,243,36,1) 0%, rgba(115,79,204,1) 33%, rgba(69,40,247,1) 66%, rgba(38,230,243,1) 100%)`,
                  }),
                }}
                key={i}
              >
                <dl
                  className={`${$tableRow} group-hover:bg-[#eee] transition-all duration-300`}
                >
                  <dd className="w-[60px] ml-[10px]">
                    <p
                      className={`${$labelStyle} bg-[#000] !text-white rounded-[10px] p-[3px] py-[5px] h-[30px]`}
                    >
                      {String(i + 1)}
                    </p>
                  </dd>
                  <dd className="w-[100px] ml-[20px]">
                    <p
                      className={`${$labelStyle} overflow-hidden text-ellipsis w-[70px] lg:w-[100px] hover:text-[#1F3BE6] transition-all duration-300 cursor-pointer`}
                      onClick={() =>
                        window.open(
                          `https://www.mooar.com/account?account=${item.address}`
                        )
                      }
                    >
                      {item.address}
                    </p>
                  </dd>
                  <dd className="w-[100px]">
                    <p className={$labelStyle}>{getTier(i + 1)}</p>
                  </dd>
                  <dd className="w-[130px] desktop">
                    <p className={`${$labelStyle}`}>
                      {numberCommaFormatted(String(item.points_from_lands))}
                    </p>
                  </dd>
                  <dd className="w-[130px] desktop">
                    <p className={$labelStyle}>
                      {numberCommaFormatted(String(item.points_from_gigi))}
                    </p>
                  </dd>
                  <dd className="w-[130px] desktop">
                    <p className={$labelStyle}>
                      {numberCommaFormatted(
                        String(
                          item.points_from_gigi_trade +
                            item.points_from_land_trade
                        )
                      )}
                    </p>
                  </dd>
                  <dd className="w-[130px] mr-[10px]">
                    <p className={`${$labelStyle} !text-right`}>
                      {numberCommaFormatted(String(item.total_points))}
                    </p>
                  </dd>
                </dl>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const $rowStyle = "mt-[5px] p-[2px] rounded-[15px]";
const $tableRow =
  "w-full flex flex-row justify-between items-center text-center rounded-[15px] py-[3px] bg-white h-[44px]";
const $labelStyle =
  "text-[#000] text-[15px] font-[500] uppercase max-w-[120px] text-center ";
