import { useWeb3Modal } from "@web3modal/react";
import React, { useEffect, useMemo, useState } from "react";
import { Container, FullPageLoading } from "src/components";
import { useAccount } from "wagmi";
import { MyPoints } from "./my-points";
import { useAirdropStore } from "src/stores";
import { Leaderboard } from "./leaderboard";

export const Airdrop = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [myPoints, setMyPoints] = useState<any>(null);
  const [allPoints, setAllPoints] = useState<any>(null);

  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const { getMyPoints, getAllPoints } = useAirdropStore();

  useEffect(() => {
    if (!address) return;
    setLoading(true);
    getMyPoints(address).then((item) => {
      setLoading(false);
      if (!item) return;
      setMyPoints(item[0]);
    });
  }, [address, getMyPoints]);

  useEffect(() => {
    if (!address) return;
    setLoading(true);
    getAllPoints().then((item) => {
      setLoading(false);
      if (!item) return;
      setAllPoints(item);
    });
  }, [address, getAllPoints]);

  const ranking = useMemo(() => {
    if (!allPoints || !address) return 0;
    const idx = allPoints.findIndex(
      (item: any) => item.address.toLowerCase() === address?.toLowerCase()
    );
    return idx + 1;
  }, [allPoints, address]);

  const tier = useMemo(() => {
    switch (true) {
      case ranking > 0 && ranking <= 10:
        return "S";
      case ranking > 10 && ranking <= 50:
        return "A";
      case ranking > 50 && ranking <= 150:
        return "B";
      case ranking > 150 && ranking <= 300:
        return "C";
      case ranking > 300 && ranking <= 500:
        return "D";
      default:
        return "E";
    }
  }, [ranking]);

  return (
    <Container containerStyle="bg-gradient-to-b from-[#DEDEDE] to-[#A2A2A2]">
      {loading && <FullPageLoading transparentBg={true} />}

      {/* If no address */}
      {!address && (
        <div className="w-full justify-center items-center flex px-[30px] pb-[80px] h-screen overflow-hidden">
          <button
            className="text !py-[10px] !px-[50px] hover:!border-[#000] hover:shadow-2xl hover:shadow-[#fff]"
            onClick={() => open()}
          >
            Connect Wallet
          </button>
        </div>
      )}

      {!loading && address && (
        <div className="pt-28 w-full justify-center items-start flex px-[30px] pb-[80px] h-screen overflow-hidden">
          <div className=" max-w-[1200px] mx-auto w-full ">
            <MyPoints myPoints={myPoints} tier={tier} />

            {/* Leaderboard */}
            <div className="pt-[20px] lg:pt-[30px]">
              <Leaderboard
                address={address}
                myPoints={myPoints}
                tier={tier}
                myRank={ranking}
                allPoints={allPoints && allPoints.slice(0, 200)}
              />
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};
