import React from "react";

export const FullPageLoading = (props: any) => {
  const { transparentBg = false } = props;
  return (
    <div
      className={`${
        transparentBg ? `z-[9999]` : `bg-black z-50`
      } w-screen h-screen fixed t-0 l-0 flex items-center justify-center`}
    >
      <img
        src="/assets/Loading_512px.gif"
        alt="loading-icon"
        className="w-[120px] h-[120px]"
      />
    </div>
  );
};
