import moment from "moment";
import { useEffect, useState } from "react";
import { LoadingIcon } from "src/components/loading-icon";
import { useGameStore } from "src/stores";
import { history } from "src/stores";
import { capitalised } from "src/utils";

export const Records = (props: any) => {
  const { address } = props;
  const [records, setRecords] = useState<any>(null);

  const { getRecords } = useGameStore();

  useEffect(() => {
    getRecords("", address).then((result) => {
      setRecords(result);
    });
  }, [getRecords, address]);

  const getColor = (type: string) => {
    if (type === "stake") return "rgb(239, 68, 68)";
    if (type === "payout") return "rgb(34, 197, 94)";
    return "#000";
  };

  console.log(records);

  return (
    <div className="mt-[30px] overflow-x-hidden">
      <div className="flex flex-row items-center">
        <h2 className="flex-1 text-black">Records</h2>
      </div>

      <div className="mt-[20px]">
        <div className="flex flex-col">
          {/* Header */}
          <dl className="w-full flex flex-row justify-between items-center pb-[0px] lg:pb-[0px]">
            <dt className="w-[60px] ml-[20px]">
              <p className={$titleStype}>Type</p>
            </dt>
            <dt className="w-[150px] desktop ml-[20px]">
              <p className={`${$titleStype} !text-left`}>Game Identifier</p>
            </dt>
            <dt className="w-[100px] ml-[40px]">
              <p className={`${$titleStype} !text-left`}>Credit</p>
            </dt>
            <dt className="w-[60px] desktop">
              <p className={$titleStype}>Status</p>
            </dt>
            <dt className="w-[200px] mr-[20px]">
              <p className={`${$titleStype} !text-right`}>Time</p>
            </dt>
          </dl>

          {/* Loading Icon */}
          {!records && (
            <div className="mt-[20px] mx-auto">
              <LoadingIcon color="#000" />
            </div>
          )}

          {/* No records */}
          {records && records.length === 0 && (
            <div className="mt-[20px] mx-auto">
              <p>No records yet</p>
            </div>
          )}

          {/* Rows */}
          <div className="flex flex-col">
            {records &&
              records.length > 0 &&
              records.map((item: any, i: number) => {
                const { _id } = item.extra?.game || {};
                return (
                  <div
                    className={`${$rowStyle} group transition-all duration-300 cursor-pointer`}
                    key={i}
                    onClick={() => _id && history.push(`/game/${_id}`)}
                  >
                    <dl
                      className={`${$tableRow} group-hover:bg-[#eee] transition-all duration-300 cursor-pointer drop-shadow-none group-hover:drop-shadow-md`}
                    >
                      <dd className="w-[60px] ml-[20px]">
                        <p className={$labelStyle}>{capitalised(item.type)}</p>
                      </dd>
                      <dd className="w-[150px] ml-[20px] desktop">
                        <p className={`${$labelStyle} !text-left`}>
                          {item.extra?.game?.identifier}
                        </p>
                      </dd>
                      <dd className="w-[100px] ml-[40px]">
                        <p
                          className={`${$labelStyle} !text-left`}
                          style={{
                            color: getColor(item.type),
                          }}
                        >
                          {item.credit}
                        </p>
                      </dd>
                      <dd className="w-[60px] desktop">
                        <p className={$labelStyle}>
                          {capitalised(item.status)}
                        </p>
                      </dd>
                      <dd className="w-[200px] mr-[20px]">
                        <p className={`${$labelStyle} !text-right`}>
                          {item.updated_at
                            ? moment
                                .utc(item.updated_at)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")
                            : ""}
                        </p>
                      </dd>
                    </dl>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const $titleStype = "text-[#000] font-[500] text-[15px] text-center";
const $labelStyle = "text-[#000] font-[500] text-[15px] text-center";
const $rowStyle = "mt-[5px] p-[5px] rounded-[15px]";
const $tableRow =
  "w-full flex flex-row justify-between items-center text-center rounded-[15px] py-[3px] bg-white h-[44px]";
