import { useEffect, useState } from "react";

export const Countdown = (props: any) => {
  const { datetime, refresh } = props;

  const calculateTimeLeft = () => {
    let difference = +new Date(datetime) - +new Date();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        H: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Mins: Math.floor((difference / 1000 / 60) % 60),
        Secs: Math.floor((difference / 1000) % 60),
      };
    }

    if (difference <= 0) {
      refresh();
      return {
        H: 0,
        Mins: 0,
        Secs: 0,
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents: any = [];

  Object.keys(timeLeft).forEach((interval, i) => {
    let time = timeLeft[interval];
    if (time < 10) time = `0${time}`;

    timerComponents.push(
      <span key={i} className="countdown-font">
        {time} {interval !== "Secs" && ": "}{" "}
      </span>
    );
  });

  if (!datetime) return null;

  return <div className={`countdown`}>{timerComponents}</div>;
};
