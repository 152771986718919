import { ReactComponent as InfoIcon } from "../../components/button/icons/btn_36_info.svg";

export const MyPoints = (props: any) => {
  return (
    <div className="flex flex-col-reverse lg:flex-row items-center justify-between pl-[0px] lg:pl-[25px]">
      <div className="flex flex-col">
        <div className={$menuItems}>
          <h2 className="text-[#000] uppercase text-center lg:text-left pr-[10px]">
            Leaderboard
          </h2>
          <InfoIcon
            onClick={() =>
              window.open(
                "https://gigaspace.medium.com/earn-airdrops-with-gigaspace-leaderboard-d98aed7ad775"
              )
            }
          />
        </div>

        <p className="text-[#000] text-[15px] pt-[10px] font-[400] max-w-[1000px] desktop">
          Earn points by actively participating in the GigaSpace ecosystem,
          including holding and trading GigaSpace Spaceland and GIGI NFTs.{" "}
          <br />
          Climb the ranks, compete with fellow space travelers, and unlock
          exclusive rewards.
          <br />
          <span className="font-[500]">
            This leaderboard is updated every 24 hours.
          </span>
        </p>
      </div>
    </div>
  );
};

const $menuItems =
  "flex flex-row cursor-pointer items-center w-[300px] lg:w-[500px] lg:pt-[0px] airdrop-header-btn";
