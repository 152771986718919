export const setToken = (token: string) =>
  localStorage.setItem("gigaspace-web-token", token);

export const getToken = (): string =>
  localStorage.getItem("gigaspace-web-token") ?? "";

export const removeToken = () => {
  localStorage.removeItem("gigaspace-web-token");
};

export const hasLogin = (): boolean => (getToken().length > 0 ? true : false);
