import React from "react";
import { CloseButton } from "../close-btn";

export const Trailer = (props: any) => {
  const { setShowTrailer } = props;
  return (
    <div className="fixed top-0 left-0 w-[100vw] h-[100vh] z-[9999] bg-black flex justify-center items-center">
      <video className="w-full object-cover" controls playsInline autoPlay>
        <source src="/assets/001.mp4" type="video/mp4" />
      </video>

      {/* Close btn */}
      <CloseButton handleCancel={() => setShowTrailer(false)} type="white" />
    </div>
  );
};
