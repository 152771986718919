import { Modal } from "antd";
import React from "react";
import { CloseButton } from "./close-btn";
export const ComingSoonModal = (props: any) => {
  const { isModalOpen, setIsModalOpen } = props;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={``}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} type="black" />

      <div className="pb-[50px] flex flex-col justify-center align-center text-left min-h-[200px]">
        <p className="text-[16px] uppercase pt-10 text-center">Coming Soon</p>
      </div>
    </Modal>
  );
};
