import { Modal, Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { API_URL } from "src/config";
import ImgCrop from "antd-img-crop";
import { beforeUpload, getBase64, toDataUrl } from "src/utils";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import { generateLandImg } from "src/components/map/land-image";
import { useMapStore } from "src/stores";
import { ShareButtons } from "src/components";
import { CloseButton } from "src/components/close-btn";

const pClass = "text-[12px] ml-[10px] uppercase";
const inputClass =
  "mt-[5px] px-[10px] py-[10px] bg-[#f5f5f5] border text-[#000] text-[12px] shadow-sm border-[1px] border-[#f5f5f5] placeholder:text-[12px] placeholder:!text-[#ccc] rounded-[10px] hover:outline-none hover:border-[#000] block w-full rounded-md lg:text-sm duration-300 transition-all";

export const EditLandModal = (props: any) => {
  const {
    isModalOpen,
    setIsModalOpen,
    land,
    messageApi,
    getLands,
    setLicenses,
    setLoading,
    address,
  } = props;
  const { land_name, land_desc } = land || {};
  const [previewImg, setPreviewImg] = useState<any>(undefined); // For preview image
  const [previewImgList, setPreviewImgList] = useState<any>([]); // For preview image
  const [logoList, setLogoList] = useState<UploadFile[]>([]); // For logo images
  const [fileList, setFileList] = useState<UploadFile[]>([]); // For preview images
  const [featureImg, setFeatureImg] = useState<any>(undefined); // For logo images
  const [name, setName] = useState(land_name);
  const [desc, setDesc] = useState(land_desc);
  const [saving, setSaving] = useState(false);

  // Store
  const { getAllLands, updateLand } = useMapStore();

  const getImg = useCallback(
    async (cord: any, type: any, lands: any) => {
      let dataUrl: any = await generateLandImg(cord, type, lands);
      setFeatureImg(dataUrl);
    },
    [setFeatureImg]
  );

  // UseEffect Hooks
  useEffect(() => {
    // Update name & desc
    setFeatureImg(true);
    if (land) {
      if (land.land_name) setName(land.land_name);
      if (land.land_desc) setDesc(land.land_desc);
      if (land.img_url) {
        let logoData: string = `${API_URL}/images/${land.img_url}`;
        toDataUrl(logoData, (result: any) => {
          if (result) {
            // setImageUrl(result)
            setLogoList([
              {
                uid: "1",
                name: "avatar",
                thumbUrl: result,
              },
            ]);
          }
        });
      }

      // Update default feature image
      // getAllLands().then((result) => {
      //   if (!result) return;
      //   getImg(
      //     { x: parseInt(land.x), y: parseInt(land.y) },
      //     parseInt(land.type),
      //     result
      //   );
      // });

      if (land.previewImgs && land.previewImgs.length > 0) {
        let fileRes: any = [];
        for (let i in land.previewImgs) {
          let previewImg: string = `${API_URL}/images/${land.previewImgs[i].img_url}`;
          // eslint-disable-next-line
          toDataUrl(previewImg, (result: any) => {
            if (result) {
              fileRes = [
                ...fileRes,
                {
                  uid: String(parseInt(i) + 1),
                  name: land.previewImgs[i].token_id,
                  thumbUrl: result,
                },
              ];
              setFileList(fileRes);
            }
          });
        }
      }
    }
  }, [land, setName, setDesc, getImg, getAllLands]);

  // Monitor Preview Image List
  useEffect(() => {
    if (previewImgList && previewImgList.length > 0) {
      let lastIdx = previewImgList.length - 1;
      if (previewImgList[lastIdx].originFileObj) {
        getBase64(previewImgList[lastIdx].originFileObj as RcFile, (url) => {
          return setPreviewImg(url);
        });
      }
      return setPreviewImg(previewImgList[0].thumbUrl);
    }

    return setPreviewImg(undefined);
  }, [previewImgList, setPreviewImg]);

  if (!land) return null;

  let logoUrl =
    land && land.tokenId ? `${API_URL}/images/${land.tokenId}.jpg` : undefined;

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleCancel = () => {
    // if (!featureImg)
    //   return messageApi.warning(
    //     "Fetching Land License Image...Please wait a second..."
    //   );
    setName(undefined);
    setDesc(undefined);
    setFeatureImg(null);
    setLogoList([]);
    setFileList([]);
    setPreviewImg(undefined);
    setIsModalOpen(false);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setLogoList(newFileList);
    setPreviewImgList(newFileList);
  };

  const handlePreviewImageChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
    setPreviewImgList(newFileList);
  };

  const save = async () => {
    if (!featureImg)
      return messageApi.warning(
        "Fetching Land License Image...Please wait a second..."
      );

    let previewImgs: any = [];
    let imageUrl = undefined;
    if (logoList && logoList.length > 0) {
      imageUrl = logoList[0].thumbUrl;
    }
    if (fileList && fileList.length > 0) {
      for (let file of fileList) {
        if (!file.thumbUrl) continue;
        previewImgs = [...previewImgs, file.thumbUrl];
      }
    }

    // Reset 1x1 images
    if (land && land.type === 1) {
      imageUrl = null;
      previewImgs = [];
    }

    let payload = {
      fileList,
      imageUrl,
      name,
      desc,
      land,
      previewImgs,
      // featuredImg: featureImg,
    };
    setSaving(true);
    let result = await updateLand(payload);
    setSaving(false);
    if (!result) return messageApi.error("Failed to save Land License");

    getLands(`owner=${address}`).then((result: any) => {
      setLoading(false);
      if (!result) return;
      setLicenses(result);
    });
    return messageApi.success("Land license is saved");
  };

  const uploadButton = (
    <div className="antd-upload-svg">
      <PlusOutlined color="#ccc" />
    </div>
  );

  return (
    <Modal
      title={`EDIT  ${land.x}, ${land.y}`}
      open={isModalOpen}
      onOk={() => null}
      onCancel={handleCancel}
      footer={null}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} type="black" />
      <div className="my-[30px] px-[0px] lg:px-[50px]">
        <div className="flex flex-col lg:flex-row justify-center align-center text-left">
          <div className="mt-[20px]">
            <img
              src={previewImg || logoUrl}
              alt="land-img"
              className="w-[100%] lg:w-[360px] object-fit"
            />
          </div>

          <div className="flex flex-col pt-[20px] lg:pt-[0px] pl-0 lg:pl-[30px] min-w-[auto] lg:min-w-[340px]">
            <div>
              <p className={pClass}>Name</p>
              <input
                placeholder="EDIT"
                className={inputClass}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </div>

            <div className="mt-[20px]">
              <p className={pClass}>Land Description</p>
              <textarea
                name="desc"
                placeholder="EDIT"
                className={inputClass}
                value={desc}
                onChange={(e: any) => setDesc(e.target.value)}
                rows={5}
              ></textarea>
            </div>

            <div className="mt-[20px]">
              <div className="flex flex-col lg:flex-row">
                {/* Upload Logo */}
                <div>
                  <p className={pClass}>Logo</p>
                  <div className="upload-logo mt-[5px]">
                    <ImgCrop>
                      <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={{
                          showPreviewIcon: false,
                          showRemoveIcon: true,
                        }}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        customRequest={dummyRequest}
                        fileList={logoList}
                        onRemove={() => setLogoList([])}
                      >
                        {logoList.length >= 1 ? null : uploadButton}
                      </Upload>
                    </ImgCrop>
                  </div>
                </div>

                {/* Upload Feature Images */}
                <div className="flex flex-col items-start lg:items-center ml-[0px] lg:ml-[30px]">
                  <p className={`${pClass} lg:ml-[0px]`}>
                    Feature Images (Max.2)
                  </p>
                  <div className="upload-preview flex flex-row mt-[5px]">
                    <ImgCrop>
                      <Upload
                        listType="picture-card"
                        fileList={fileList}
                        beforeUpload={beforeUpload}
                        onChange={handlePreviewImageChange}
                        customRequest={dummyRequest}
                        showUploadList={{ showPreviewIcon: false }}
                      >
                        {fileList.length >= 2 ? null : uploadButton}
                      </Upload>
                    </ImgCrop>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Upload Notes */}
        <div className="mt-[20px]">
          <p className="text-center font-[400] text-[12px]">
            You can upload an image to be shown on the map (jpeg or png). The
            file size should be less than 5mb.
          </p>
        </div>
      </div>

      {/* Social & Save button */}
      <div className="mt-[30px] flex flex-row items-end pb-[28px]">
        {/* Social */}
        <ShareButtons land={land} />
        {/* Save */}
        <div className="flex items-end">
          <button
            className="text alternate w-[150px]"
            onClick={() => save()}
            disabled={saving}
            style={saving ? { background: "#000" } : {}}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};
