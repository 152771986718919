import React from "react";
import { API_URL } from "src/config";
import { getLandTypeName } from "src/utils";

export const License = (props: any) => {
  const { land, setLicense, setOpenEditLandModal } = props;
  if (!land || !land.tokenId) return null;
  let imageUrl = `${API_URL}/images/${land.tokenId}.jpg`;
  return (
    <div className="flex flex-col pb-[15px] lg:p-[15px] bg-[#141414] w-[100%] rounded-[15px] pb-[30px]">
      <img
        src={imageUrl}
        className="w-[100%]object-fit rounded-t-[15px] lg:rounded-t-[0px]"
        alt="land-img"
      />

      <div className="flex flex-row items-end mt-[20px] px-[15px] lg:px-[0]">
        <div className="flex flex-col flex-1">
          <p className="uppercase text-[12px]">
            {land.x}, {land.y}
          </p>
          <p className="uppercase text-[12px]">
            {getLandTypeName(parseFloat(land.type))}
          </p>
        </div>

        <div>
          <p
            className="uppercase text-[12px] text-[#505050] hover:!text-[#d8d8d8] duration-300 transition-all cursor-pointer"
            onClick={() => {
              setLicense(land);
              setOpenEditLandModal(true);
            }}
          >
            Edit
          </p>
        </div>
      </div>
    </div>
  );
};
