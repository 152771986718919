import { numberCommaFormatted } from "src/utils";
import { ReactComponent as InfoIcon } from "../../components/button/icons/btn_36_info.svg";
import { SelectPage } from "./select-page";

export const MyPoints = (props: any) => {
  const { user, tab, setTab } = props;
  const { credit = 0 } = user || {};
  return (
    <div className="flex flex-col-reverse lg:flex-row items-start justify-start">
      <div className="flex flex-col">
        <div className={$menuItems}>
          <h2 className="text-[#000] uppercase text-center lg:text-left pr-[10px]">
            Giga Prediction
          </h2>
          <InfoIcon
            onClick={() =>
              window.open(
                "https://gigaspace.medium.com/earn-airdrops-with-gigaspace-leaderboard-d98aed7ad775"
              )
            }
          />
        </div>

        <div className="mt-[10px]">
          <div className={$labelColStyle}>
            <p className={$labelStyle}>My Giga Credits</p>
            <h2 className={$valueStyle}>
              {numberCommaFormatted(String(credit))}
            </h2>
          </div>
        </div>

        <p className="text-[#000] text-[15px] pt-[10px] font-[400] max-w-[1000px] desktop">
          Earn credits by actively participating in the GigaSpace Prediction
          Game. <br />
          Compete with fellow space travelers, earn Giga Credit, and claim
          exclusive rewards.
          <br />
          <span className="font-[500]">
            The Giga Shop will be updated each month.
          </span>
        </p>

        <div className="mt-[20px]">
          <div className="mobile">
            <SelectPage tab={tab} setTab={setTab} />
          </div>
          <div className="desktop">
            <div className={`flex-row flex-wrap flex`}>
              <button
                className="text !px-[20px]"
                onClick={() => setTab("games")}
              >
                Games
              </button>
              <button
                className="text !px-[20px] ml-[20px]"
                onClick={() => setTab("shop")}
              >
                Shop
              </button>
              <button
                className="text !px-[20px] ml-[20px]"
                onClick={() => setTab("records")}
              >
                Records
              </button>
              <button
                className="text !px-[20px] ml-[20px]"
                onClick={() => setTab("leaderboard")}
              >
                Leaderboard
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const $menuItems =
  "flex flex-row cursor-pointer items-center w-[300px] lg:w-[500px] lg:pt-[0px] airdrop-header-btn";
const $labelColStyle = "flex flex-col justify-start items-start";
const $labelStyle = "text-[#000] text-[15px] font-[500] uppercase text-left";
const $valueStyle = "text-[#000] text-[34px] font-[500] uppercase pt-[0px]";
