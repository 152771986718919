export const Leaderboard = (props: any) => {
  return (
    <div className="mt-[30px] overflow-x-hidden">
      <div className="flex flex-row items-center">
        <h2 className="flex-1 text-black">Leaderboard</h2>
      </div>

      <div className="mt-[20px]">
        <p className="text-center text-black">Coming soon</p>
      </div>
    </div>
  );
};
