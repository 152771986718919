import { getLandTypeName } from "src/utils";

export const ShopItem = (props: any) => {
  const { image, name = "Spaceland 1x1", type = 1 } = props;
  return (
    <div className="flex flex-col pb-[15px] bg-[#fff] w-[100%] rounded-[15px] pb-[30px]">
      <img
        src={image}
        className="w-[100%] object-fit rounded-t-[15px] bg-black p-[20px]"
        alt="land-img"
      />

      <div className="flex flex-row items-end mt-[20px] px-[15px] ">
        <div className="flex flex-col flex-1">
          <p className="text-black uppercase text-[12px]">{name}</p>
          <p className="text-black  uppercase text-[12px]">
            {getLandTypeName(parseFloat(type))}
          </p>
        </div>

        <div>
          <p
            className="uppercase text-[12px] text-[#505050] hover:!text-[#d8d8d8] duration-300 transition-all cursor-pointer"
            onClick={() => null}
          >
            Coming Soon
          </p>
        </div>
      </div>
    </div>
  );
};
