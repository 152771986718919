import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { openseaUrl } from "src/config";
import { ReactComponent as TwiiterIcon } from "../button/icons/btn_19_twitter.svg";
import { ReactComponent as FacebookIcon } from "../button/icons/btn_24_facebook.svg";
import { ReactComponent as LinkedinIcon } from "../button/icons/btn_25_linkedin.svg";

export const ShareButtons = (props: any) => {
  const { land } = props;
  if (!land) return null;
  const renderIcon = (icon: string) => {
    let color = "black";
    let padding = 5;
    let fontSize = 20;
    let iconStyle = { color: color, padding: padding, fontSize: fontSize };
    if (icon === "twitter")
      return <TwiiterIcon style={iconStyle} className="share-icons" />;
    if (icon === "facebook")
      return <FacebookIcon style={iconStyle} className="share-icons" />;
    if (icon === "linkedin")
      return <LinkedinIcon style={iconStyle} className="share-icons" />;
    return "";
  };

  return (
    <div className="flex flex-row items-center share-buttons flex-1">
      <TwitterShareButton
        title={`Gigaspace Land ${land.x}, ${land.y} ${
          land.land_name ? ` - ${land.land_name}` : ""
        }`}
        via="Gigaspace2140"
        url={`\n\nLet's check out my Gigaspace land at ${openseaUrl}/${land.tokenId}\n\n`}
        hashtags={[
          "gigaspace",
          "metaverse",
          "opensea",
          "web3",
          "landNFT",
          "gigaspace2140",
          "NFT",
        ]}
      >
        {" "}
        {renderIcon("twitter")}
      </TwitterShareButton>
      <FacebookShareButton
        title={`Gigaspace Land ${land.x}, ${land.y} ${
          land.land_name ? ` - ${land.land_name}` : ""
        }`}
        quote={`Let's check out my Gigaspace land at ${openseaUrl}/${land.tokenId}`}
        url={`${openseaUrl}/${land.tokenId}`}
      >
        {renderIcon("facebook")}
      </FacebookShareButton>

      <LinkedinShareButton
        title={`Gigaspace Land ${land.x}, ${land.y} ${
          land.land_name ? ` - ${land.land_name}` : ""
        }`}
        summary={`Let's check out my Gigaspace land at ${openseaUrl}/${land.tokenId}`}
        url={`${openseaUrl}/${land.tokenId}`}
      >
        {renderIcon("linkedin")}
      </LinkedinShareButton>
    </div>
  );
};
