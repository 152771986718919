import { Modal } from "antd";
import React, { useMemo } from "react";
import { CloseButton } from "../close-btn";
export const ViewLandModal = (props: any) => {
  const { isModalOpen, setIsModalOpen, land, messageApi } = props;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const lands = useMemo(() => {
    if (!land) return [];
    const { x, y, type } = land;

    let coords: any = [];
    for (let i = x; i < x + type; i++) {
      for (let q = y; q < y + type; q++) {
        coords = [
          ...coords,
          {
            x: i,
            y: q,
          },
        ];
      }
    }

    return coords;
  }, [land]);

  return (
    <Modal
      title={`${land.x}, ${land.y}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} type="black" />
      <div className="my-[30px] flex flex-col justify-center align-center text-left">
        <button
          className="w-[100%] text"
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(lands));
            messageApi.success("Parcels are copied!");
          }}
        >
          Copy all parcels
        </button>
        <p className="text-[12px] uppercase pt-10">Parcels</p>

        <div className="mt-5 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-[15px]">
          {lands &&
            lands.map((item: any, i: any) => {
              return (
                <div
                  key={i}
                  className={`flex flex-row justify-center items-center border-[1px] border-[#000] p-[10px] rounded-[5px] pl-[0px]`}
                >
                  <img
                    src="/assets/btn_02_map.svg"
                    alt="location"
                    className="w-[20px] h-[20px] mr-[10px]"
                  />
                  <p>
                    {item.x}, {item.y}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};
