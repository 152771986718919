import React from "react";
import { Button } from "../button";
import { history } from "src/stores";
import { ReactComponent as MapIcon } from "../button/icons/btn_02_map.svg";
import { ReactComponent as MetaverseIcon } from "../button/icons/btn_01_metaverse.svg";
import { mataverseHost } from "src/config";

const SOCIAL_IMG_STYLE = "ml-[10px] w-[25px] h-[25px] cursor-pointer";

export const Footer = (props: any) => {
  const { setShowTrailer, setShowComingSoonModal } = props;

  const redirect = (url: string, type: "url" | "page", self: boolean) => {
    if (type === "url") return window.open(url, self ? "_self" : "_blank");
    if (type === "page") return history.push(url);
  };

  return (
    <div className="fixed bottom-0 left-0 w-[100%] flex flex-row z-10 py-[30px] px-[30px] items-center">
      {/* Buttons */}
      <div className="!hidden lg:!flex flex-row items-center justify-start ">
        <div>
          <Button icon="trailer" action={() => setShowTrailer(true)} />
        </div>

        <div className="ml-[10px]">
          <Button
            icon="opensea"
            action={() =>
              redirect(
                "https://opensea.io/collection/gigaspace-2140",
                "url",
                false
              )
            }
          />
        </div>

        <div className="ml-[10px]">
          <Button
            icon="mooar"
            action={() =>
              redirect(
                "https://www.mooar.com/collection/0xE04162cdeCC532F6840a3066a0664F91DbC0Ea3b",
                "url",
                false
              )
            }
          />
        </div>
      </div>

      {/* Mobile Icons */}
      <div className="!flex flex-row items-center justify-start flex-1 lg:!hidden">
        <div
          className="mobile-icon mr-[10px]"
          onClick={() => history.push("/map")}
        >
          <MapIcon />
        </div>
        <div
          className="mobile-icon"
          onClick={() =>
            mataverseHost
              ? window.open(mataverseHost)
              : setShowComingSoonModal(true)
          }
        >
          <MetaverseIcon />
        </div>
      </div>

      {/* Social Icons */}
      <div className="!hidden lg:!flex flex-row items-center justify-end flex-1">
        <p>@GIGASPACE</p>
        <img
          src="/assets/icons-white/icon_02_twitter.svg"
          alt="twitter-icon"
          className={SOCIAL_IMG_STYLE}
          onClick={() =>
            redirect("https://twitter.com/Gigaspace2140", "url", false)
          }
        />
        <img
          src="/assets/icons-white/icon_03_discord.svg"
          alt="discord-icon"
          className={SOCIAL_IMG_STYLE}
          onClick={() => redirect("https://discord.gg/gigaspace", "url", false)}
        />
        <img
          src="/assets/icons-white/icon_04_instagram.svg"
          alt="instagram-icon"
          className={SOCIAL_IMG_STYLE}
          onClick={() =>
            redirect("https://instagram.com/gigaspace2140", "url", false)
          }
        />
        <img
          src="/assets/icons-white/icon_05_youtube.svg"
          alt="youtube-icon"
          className={SOCIAL_IMG_STYLE}
          onClick={() =>
            redirect("https://www.youtube.com/@gigaspace", "url", false)
          }
        />
        <img
          src="/assets/icons-white/icon_06_medium.svg"
          alt="medium-icon"
          className={SOCIAL_IMG_STYLE}
          onClick={() =>
            redirect("https://medium.com/@gigaspace", "url", false)
          }
        />
      </div>
    </div>
  );
};
