import { Modal } from "antd";
import React, { useMemo } from "react";
import { getAcceptedTokens } from "src/config";
import { getLandTypeName } from "src/utils";
import { CloseButton } from "../close-btn";
import { LoadingIcon } from "../loading-icon";

export const SelectTokenModal = (props: any) => {
  const {
    isModalOpen,
    setIsModalOpen,
    land,
    address,
    selectedToken,
    setSelectedToken,
    getPrice,
    mint,
    isLoading,
  } = props;

  const handleOk = () => {
    if (isLoading) return;
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    if (isLoading) return;
    setIsModalOpen(false);
  };

  const tokens = useMemo(() => {
    if (!address) return [];
    return getAcceptedTokens(address);
  }, [address]);

  return (
    <Modal
      title={`${land.x}, ${land.y}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} type="black" />
      <div className="my-[30px] flex flex-col justify-center align-center text-center">
        <p className="text-[12px] uppercase">Select Token</p>
        <div className="pt-[5px]">
          {tokens.map((item: any, i: any) => {
            return (
              <div
                key={i}
                className={`flex flex-row w-[100%] lg:w-[350px] mx-auto mt-[10px] items-center py-[10px] px-[15px] border-[1px] rounded-[10px] bg-[#F5F5F5] cursor-pointer duration-300 transition-shadow transition-colors hover:bg-[#FFF] hover:drop-shadow-lg ${
                  selectedToken === item ? "border-[#000]" : "border-[#FFF]"
                }`}
                onClick={() => setSelectedToken(item)}
              >
                <p className="text-[12px] uppercase flex flex-1">{item}</p>
                <img
                  src={`/assets/${item === "tier" ? "gmt" : item}.png`}
                  className="w-[25px] h-[25px] object-fit"
                  alt={`${item}-icon`}
                />
              </div>
            );
          })}
        </div>

        <div className="flex flex-col w-[100%] lg:w-[350px] mx-auto items-start pt-[15px]">
          <p className="text-[12px] uppercase">{selectedToken}</p>
          <h3 className="pt-[10px] text-black uppercase tracking-[0px] leading-[20px] font-[500] text-[25px]">
            PRICE : {getPrice(selectedToken)}
          </h3>
        </div>

        <div className="flex flex-col sm:flex-row items-end pt-20">
          <p className="text-[12px] uppercase flex flex-1 mb-[5px] sm:mb-[0]">
            {getLandTypeName(land["type"])} {land["type"]} x {land["type"]} (
            {land["type"] * land["type"]} parsel{land["type"] !== 1 && `s`} )
          </p>

          <button
            className="text alternate w-[150px]"
            onClick={() => mint()}
            disabled={isLoading}
            style={isLoading ? { background: "#000" } : {}}
          >
            {isLoading ? <LoadingIcon /> : "Buy Now"}
          </button>
        </div>
      </div>
    </Modal>
  );
};
