import React from "react";
import { ReactComponent as Profile } from "../button/icons/btn_06_profile.svg";
import { ReactComponent as Map } from "../button/icons/btn_02_map.svg";
import { ReactComponent as MetaverseIcon } from "../button/icons/btn_01_metaverse.svg";
import { ReactComponent as OpenseaIcon } from "../button/icons/btn_04_opensea.svg";
import { ReactComponent as TrailerIcon } from "../button/icons/btn_03_trailer.svg";
import { ReactComponent as MetamaskIcon } from "../button/icons/btn_29_metamask.svg";
import { ReactComponent as LogoutIcon } from "../button/icons/btn_05_logout.svg";
import { ReactComponent as CopyIcon } from "../button/icons/btn_12_copy.svg";
import { ReactComponent as TwitterIcon } from "../button/icons/btn_19_twitter.svg";
import { ReactComponent as DiscordIcon } from "../button/icons/btn_20_discord.svg";
import { ReactComponent as InstagramIcon } from "../button/icons/btn_21_instagram.svg";
import { ReactComponent as YoutubeIcon } from "../button/icons/btn_22_youtube.svg";
import { ReactComponent as MediumIcon } from "../button/icons/btn_23_medium.svg";
import { ReactComponent as MooarIcon } from "../button/icons/btn_33_mooar.svg";
import { ReactComponent as AirdropIcon } from "../button/icons/btn_35_airdrop.svg";

import { history } from "src/stores";
import { useAccount, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { CloseButton } from "../close-btn";
// import { mataverseHost } from "src/config";

const menuItems =
  "flex flex-row menu-item cursor-pointer items-center mobile-menu-btn mb-[10px]";
export const MobileMenu = (props: any) => {
  const { isMenuOpen, setIsMenuOpen, setShowTrailer } = props;
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  const handleCancel = () => {
    setIsMenuOpen(false);
  };

  const redirect = (
    url: string,
    type: "url" | "page" | "trailer",
    self?: boolean
  ) => {
    if (type === "url") window.open(url, self ? "_self" : "_blank");
    if (type === "page") history.push(url);
    if (type === "trailer") {
      setShowTrailer(true);
    }
    setIsMenuOpen(false);
  };

  return (
    <div
      id="mobile-menu"
      className={`bg-black w-[100%] ${
        isMenuOpen
          ? "h-full z-[2000] overflow-hidden overflow-y-scroll"
          : "h-[0] invisible overflow-hidden"
      } fixed top-[0] left-[0] transition-all duration-300 p-[30px]`}
    >
      <div className="flex flex-row items-center">
        {/* Logo */}
        <img
          src="/assets/Giga_Logo_2140.svg"
          alt="logo"
          className="cursor-pointer w-[164px] relative top-[6px]"
          onClick={() => history.push("/")}
        />

        {/* Close btn */}
        <CloseButton handleCancel={handleCancel} type="white" />
      </div>

      {/* Buttons */}
      <div className="mt-10">
        <div
          className={menuItems}
          onClick={() => redirect("/land-licenses", "page", false)}
        >
          <p>Profile</p>
          <Profile />
        </div>
        <div
          className={menuItems}
          onClick={() => redirect("/airdrop", "page", false)}
        >
          <p>Airdrop</p>
          <AirdropIcon />
        </div>
        <div
          className={menuItems}
          onClick={() => redirect("/map", "page", false)}
        >
          <p>Map</p>
          <Map />
        </div>
        <div
          className={menuItems}
          onClick={() => redirect("/game", "page", false)}
          // onClick={() =>
          //   mataverseHost
          //     ? window.open(mataverseHost)
          //     : setShowComingSoonModal(true)
          // }
        >
          <p>Game</p>
          <MetaverseIcon />
        </div>
        <div
          className={menuItems}
          onClick={() =>
            redirect(
              "https://opensea.io/collection/gigaspace-2140",
              "url",
              false
            )
          }
        >
          <p>Opensea</p>
          <OpenseaIcon />
        </div>
        <div
          className={menuItems}
          onClick={() =>
            redirect(
              "https://www.mooar.com/collection/0xE04162cdeCC532F6840a3066a0664F91DbC0Ea3b",
              "url",
              false
            )
          }
        >
          <p>Mooar</p>
          <MooarIcon />
        </div>
        <div className={menuItems} onClick={() => redirect("", "trailer")}>
          <p>Play Trailer</p>
          <TrailerIcon />
        </div>

        {!address && (
          <div className={`${menuItems} metamask`} onClick={() => open()}>
            <p>Metamask</p>
            <MetamaskIcon />
          </div>
        )}

        {address && (
          <>
            <div
              className={menuItems}
              onClick={() => navigator.clipboard.writeText(address)}
            >
              <p className="overflow-hidden text-ellipsis pr-[40px]">
                {address}
              </p>
              <CopyIcon />
            </div>

            <div className={menuItems} onClick={() => disconnect()}>
              <p>Logout</p>
              <LogoutIcon />
            </div>
          </>
        )}
      </div>

      <div className="mt-10">
        <p className="text-white text-[15px] uppercase text-center font-[400]">
          @ Gigaspace
        </p>
      </div>

      <div className="mt-10 flex flex-row space-x-[20px] justify-evenly">
        <div
          className="menu-social-icon"
          onClick={() =>
            redirect("https://twitter.com/Gigaspace2140", "url", false)
          }
        >
          <TwitterIcon />
        </div>
        <div
          className="menu-social-icon"
          onClick={() => redirect("https://discord.gg/gigaspace", "url", false)}
        >
          <DiscordIcon />
        </div>
        <div
          className="menu-social-icon"
          onClick={() =>
            redirect("https://instagram.com/gigaspace2140", "url", false)
          }
        >
          <InstagramIcon />
        </div>
        <div
          className="menu-social-icon"
          onClick={() =>
            redirect("https://www.youtube.com/@gigaspace", "url", false)
          }
        >
          <YoutubeIcon />
        </div>
        <div
          className="menu-social-icon"
          onClick={() =>
            redirect("https://medium.com/@gigaspace", "url", false)
          }
        >
          <MediumIcon />
        </div>
      </div>
    </div>
  );
};
