import React from "react";

const COLOR_BOX = "w-[12px] h-[12px]";
const TEXT_STYLE = "uppercase text-[12px]";
const INFO_ROW_STYLE = "items-center flex flex-row mt-[20px]";
export const LandInfo = (props: any) => {
  const { setShowLandInfo } = props;

  return (
    <div className="rounded-[10px] bg-black p-[20px] pb-[30px] pr-[40px] absolute left-[30px] bottom-[150px] sm:bottom-[90px] z-[1002]">
      <p className={TEXT_STYLE}>Land</p>

      <div className="flex flex-col">
        <div className={INFO_ROW_STYLE}>
          <div
            className={`${COLOR_BOX}`}
            style={{ backgroundColor: "#3c3c3c" }}
          />
          <p className={`${TEXT_STYLE} ml-[15px]`}>available soon</p>
        </div>

        <div className={INFO_ROW_STYLE}>
          <div
            className={`${COLOR_BOX}`}
            style={{ backgroundColor: "#6e6e6e" }}
          />
          <p className={`${TEXT_STYLE} ml-[15px]`}>sold</p>
        </div>

        <div className={INFO_ROW_STYLE}>
          <div
            className={`${COLOR_BOX}`}
            style={{ backgroundColor: "#1ffff3" }}
          />
          <p className={`${TEXT_STYLE} ml-[15px]`}>your spaceland</p>
        </div>

        <div className={INFO_ROW_STYLE}>
          <div
            className={`${COLOR_BOX}`}
            style={{ backgroundColor: "#ff008a" }}
          />
          <p className={`${TEXT_STYLE} ml-[15px]`}>selected</p>
        </div>
      </div>

      {/* Close Button */}
      <div
        className="absolute top-[13px] right-[10px] z-10 w-[30px] h-[30px] cursor-pointer"
        onClick={() => setShowLandInfo(false)}
      >
        <img
          src="/assets/icons-white/btn_11_close.svg"
          className="w-full h-full p-[2.5px] object-contain"
          alt="close-btn"
        />
      </div>
    </div>
  );
};
