import { useCallback, useEffect, useState } from "react";
import { Container, FullPageLoading } from "src/components";
import { useAccount } from "wagmi";
import { useGameStore } from "src/stores";
import { useParams } from "react-router";
import { FeaturedGame } from "src/components/featured-game";

export const GameDetail = (props: any) => {
  const { address } = useAccount();
  const { getGameDetail } = useGameStore();

  const params: any = useParams();
  const { id = "" } = params;

  const [game, setGame] = useState<any>(null);

  const getGameDetailCb = useCallback(async () => {
    if (!id) return;
    const result = await getGameDetail(id);
    if (!result) return;
    setGame(result);
  }, [id, getGameDetail]);

  useEffect(() => {
    getGameDetailCb();
  }, [getGameDetailCb]);

  return (
    <Container>
      {/* Not Login */}
      {!address && (
        <div className="text-center pt-[48px] mt-20">
          <p className="uppercase">Please first connect to your wallet</p>
        </div>
      )}

      {address && (
        <>
          {!game && <FullPageLoading transparentBg={true} />}

          <div className="pt-[130px] max-w-[1200px] mx-auto px-[30px] 2xl:px-[0px] pb-[80px]">
            {game && (
              <>
                <FeaturedGame
                  game={game}
                  refresh={getGameDetailCb}
                  back={true}
                />
              </>
            )}
          </div>
        </>
      )}
    </Container>
  );
};
