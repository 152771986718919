import React, { useEffect, useState } from "react";
import { Container, FullPageLoading, LeafMap } from "src/components";
import { useMapStore } from "src/stores";

export const Map = (props: any) => {
  const { getAllLands } = useMapStore();
  const [lands, setLands] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getAllLands().then((result) => {
      setLoading(false);
      if (!result) return;
      setLands(result);
    });
  }, [getAllLands]);

  return (
    <Container>
      {loading && <FullPageLoading />}
      {!loading && lands && lands.length > 0 && (
        <LeafMap lands={lands} setLands={setLands} />
      )}
    </Container>
  );
};
