import React, { useMemo } from "react";
import { Rectangle } from "react-leaflet";
import { LatLngBounds } from "leaflet";
export const SelectedLand = (props: any) => {
  const { selectedLand: land, zoomLevel } = props;
  const type = parseFloat(land["type"]);
  const x1 = parseFloat(land["x"]);
  const y1 = parseFloat(land["y"]) - 1;
  const x2 = x1 + type;
  const y2 = y1 + type;

  const bounds: any = [
    [y1, x1],
    [y2, x1], // -1 fitting the background
    [y2, x2], // -1 fitting the background
    [y1, x2], // -1 fitting the background
  ];

  const lineWidth = useMemo(() => {
    // if (!showMap) return 0;
    if (zoomLevel > 4) return 4;
    if (zoomLevel > 2) return 2;
    return 1;
  }, [zoomLevel]);

  return (
    <Rectangle
      bounds={new LatLngBounds(bounds)}
      pathOptions={{
        color: "#ff008a",
        weight: lineWidth,
        fill: false,
      }}
    ></Rectangle>
  );
};
