import { ShopItem } from "src/components/shop-item";

export const Shop = (props: any) => {
  return (
    <div className="mt-[30px] overflow-x-hidden">
      <div className="flex flex-row items-center">
        <h2 className="flex-1 text-black">Shop</h2>
      </div>

      <div className="mt-[20px] grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-[15px]">
        <ShopItem image={"/assets/BG_Map.jpg"} type={1} name="Spaceland 1x1" />
        <ShopItem image={"/assets/BG_Map.jpg"} type={3} name="Spaceland 3x3" />
        <ShopItem
          image={"/assets/Loading_512px.gif"}
          type={99}
          name="Gigaspace"
        />
      </div>
    </div>
  );
};
