export let mainLands = [
  // Main land 1
  {
    x1: -36,
    x2: 36,
    y1: -42,
    y2: 42,
  },
  // 2
  {
    x1: -36,
    x2: 30,
    y1: -60,
    y2: -42,
  },
  // 3
  {
    x1: 30,
    x2: 36,
    y1: -60,
    y2: -45,
  },
  // 4
  {
    x1: -36,
    x2: -24,
    y1: -66,
    y2: -60,
  },
  // 5
  {
    x1: -18,
    x2: 12,
    y1: -66,
    y2: -60,
  },
  // 6
  {
    x1: -12,
    x2: -9,
    y1: -69,
    y2: -66,
  },
  // 7
  {
    x1: 24,
    x2: 27,
    y1: -63,
    y2: -60,
  },
  // 8
  {
    x1: -39,
    x2: -36,
    y1: 48,
    y2: 51,
  },
  // 9
  {
    x1: -48,
    x2: -36,
    y1: 12,
    y2: 48,
  },
  // 10
  {
    x1: -57,
    x2: -48,
    y1: 36,
    y2: 39,
  },
  // 11
  {
    x1: -60,
    x2: -48,
    y1: 12,
    y2: 36,
  },
  // 12
  {
    x1: -51,
    x2: -48,
    y1: 9,
    y2: 12,
  },
  // 13
  {
    x1: -51,
    x2: -48,
    y1: 0,
    y2: 3,
  },
  // 14
  {
    x1: -48,
    x2: -39,
    y1: 0,
    y2: 12,
  },
  // 15
  {
    x1: -39,
    x2: -36,
    y1: -30,
    y2: 12,
  },
  // 16
  {
    x1: -42,
    x2: -39,
    y1: -18,
    y2: 0,
  },
  // 17
  {
    x1: -42,
    x2: -36,
    y1: -36,
    y2: -30,
  },
  // 18
  {
    x1: -48,
    x2: -36,
    y1: -48,
    y2: -36,
  },
  // 19
  {
    x1: -45,
    x2: -36,
    y1: -51,
    y2: -48,
  },
  // 20
  {
    x1: -30,
    x2: -24,
    y1: 48,
    y2: 54,
  },
  // 21
  {
    x1: -36,
    x2: -24,
    y1: 42,
    y2: 48,
  },
  // 22
  {
    x1: -18,
    x2: -15,
    y1: 42,
    y2: 48,
  },
  // 23
  {
    x1: -12,
    x2: -9,
    y1: 51,
    y2: 54,
  },
  // 24
  {
    x1: -15,
    x2: 12,
    y1: 42,
    y2: 51,
  },
  // 25
  {
    x1: 6,
    x2: 12,
    y1: 51,
    y2: 54,
  },
  // 26
  {
    x1: 12,
    x2: 24,
    y1: 60,
    y2: 66,
  },
  // 27
  {
    x1: 27,
    x2: 30,
    y1: 60,
    y2: 63,
  },
  // 28
  // {
  //     x1: 33,
  //     x2: 36,
  //     y1: 60,
  //     y2: 66
  // },
  // 29
  {
    x1: 12,
    x2: 36,
    y1: 42,
    y2: 60,
  },
  // 30
  {
    x1: 39,
    x2: 42,
    y1: 48,
    y2: 51,
  },
  // 31
  {
    x1: 45,
    x2: 48,
    y1: 48,
    y2: 51,
  },
  // 32
  {
    x1: 36,
    x2: 48,
    y1: 36,
    y2: 48,
  },
  // 33
  {
    x1: 24,
    x2: 36,
    y1: 24,
    y2: 36,
  },
  // 34
  {
    x1: 42,
    x2: 45,
    y1: 27,
    y2: 36,
  },
  // 35
  {
    x1: 36,
    x2: 48,
    y1: 12,
    y2: 24,
  },
  // 36
  {
    x1: 36,
    x2: 39,
    y1: 0,
    y2: 12,
  },
  // 37
  {
    x1: 36,
    x2: 48,
    y1: -30,
    y2: 0,
  },
  // 38
  {
    x1: 48,
    x2: 54,
    y1: -12,
    y2: -6,
  },
  // 39
  {
    x1: 36,
    x2: 42,
    y1: -36,
    y2: -30,
  },
  // 40
  {
    x1: 36,
    x2: 39,
    y1: -51,
    y2: -45,
  },
  // New Lands
  {
    x1: 33,
    x2: 42,
    y1: 69,
    y2: 72,
  },
  {
    x1: 30,
    x2: 48,
    y1: 66,
    y2: 69,
  },
  {
    x1: 33,
    x2: 48,
    y1: 63,
    y2: 66,
  },
  {
    x1: 30,
    x2: 33,
    y1: 60,
    y2: 63,
  },
  {
    x1: 36,
    x2: 48,
    y1: 60,
    y2: 63,
  },
  {
    x1: 36,
    x2: 45,
    y1: 57,
    y2: 60,
  },
  {
    x1: 36,
    x2: 39,
    y1: 54,
    y2: 57,
  },
];

// 3x3 Land
export let lands3x3 = [
  { x1: -57, x2: -54, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -54, x2: -51, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -51, x2: -48, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -51, x2: -48, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: -51, x2: -48, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: -48, x2: -45, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: -48, x2: -45, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: -48, x2: -45, y1: 3, y2: 6, bundled: true, imgUrl: null },
  { x1: -45, x2: -42, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: -45, x2: -42, y1: 3, y2: 6, bundled: true, imgUrl: null },
  { x1: -45, x2: -42, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: -42, x2: -39, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: -42, x2: -39, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: -42, x2: -39, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: -39, x2: -36, y1: 48, y2: 51, bundled: true, imgUrl: null },
  { x1: -39, x2: -36, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: -39, x2: -36, y1: 3, y2: 6, bundled: true, imgUrl: null },
  { x1: -39, x2: -36, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 45, y2: 48, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 42, y2: 45, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 39, y2: 42, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 30, y2: 33, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 27, y2: 30, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 24, y2: 27, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 18, y2: 21, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 3, y2: 6, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: -3, y2: 0, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: -6, y2: -3, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: -9, y2: -6, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: -12, y2: -9, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: 42, y2: 45, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: 30, y2: 33, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: 27, y2: 30, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: 24, y2: 27, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: 21, y2: 24, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: -6, y2: -3, bundled: true, imgUrl: null },
  { x1: -30, x2: -27, y1: 45, y2: 48, bundled: true, imgUrl: null },
  { x1: -30, x2: -27, y1: 42, y2: 45, bundled: true, imgUrl: null },
  { x1: -30, x2: -27, y1: 39, y2: 42, bundled: true, imgUrl: null },
  { x1: -30, x2: -27, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -30, x2: -27, y1: 15, y2: 18, bundled: true, imgUrl: null },
  { x1: -30, x2: -27, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: -30, x2: -27, y1: -12, y2: -9, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: 45, y2: 48, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: 42, y2: 45, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: 39, y2: 42, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: 27, y2: 30, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: 3, y2: 6, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: -12, y2: -9, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: 39, y2: 42, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: 30, y2: 33, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: 21, y2: 24, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: 18, y2: 21, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: -3, y2: 0, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: -9, y2: -6, bundled: true, imgUrl: null },
  { x1: -21, x2: -18, y1: 39, y2: 42, bundled: true, imgUrl: null },
  { x1: -21, x2: -18, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -21, x2: -18, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: -21, x2: -18, y1: 30, y2: 33, bundled: true, imgUrl: null },
  { x1: -21, x2: -18, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: -18, x2: -15, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -18, x2: -15, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: -18, x2: -15, y1: 18, y2: 21, bundled: true, imgUrl: null },
  { x1: -18, x2: -15, y1: 12, y2: 15, bundled: true, imgUrl: null },
  // { x1: -18, x2: -15, y1: -9, y2: -6, bundled: true, imgUrl: null },
  // { x1: -18, x2: -15, y1: -12, y2: -9, bundled: true, imgUrl: null },
  { x1: -18, x2: -15, y1: -15, y2: -12, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: 39, y2: 42, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: 30, y2: 33, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: 12, y2: 15, bundled: true, imgUrl: null },
  // { x1: -15, x2: -12, y1: -3, y2: 0, bundled: true, imgUrl: null },
  // { x1: -15, x2: -12, y1: -6, y2: -3, bundled: true, imgUrl: null },
  // { x1: -15, x2: -12, y1: -9, y2: -6, bundled: true, imgUrl: null },
  // { x1: -15, x2: -12, y1: -12, y2: -9, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: -15, y2: -12, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: 51, y2: 54, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: 48, y2: 51, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: 24, y2: 27, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: 18, y2: 21, bundled: true, imgUrl: null },
  // { x1: -12, x2: -9, y1: 15, y2: 18, bundled: true, imgUrl: null },
  // { x1: -12, x2: -9, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: -9, x2: -6, y1: 48, y2: 51, bundled: true, imgUrl: null },
  { x1: -9, x2: -6, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: -9, x2: -6, y1: 30, y2: 33, bundled: true, imgUrl: null },
  // { x1: -9, x2: -6, y1: 15, y2: 18, bundled: true, imgUrl: null },
  // { x1: -9, x2: -6, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: -6, x2: -3, y1: 27, y2: 30, bundled: true, imgUrl: null },
  { x1: -6, x2: -3, y1: 48, y2: 51, bundled: true, imgUrl: null },
  { x1: -6, x2: -3, y1: 21, y2: 24, bundled: true, imgUrl: null },
  { x1: -3, x2: 0, y1: 48, y2: 51, bundled: true, imgUrl: null },
  { x1: -3, x2: 0, y1: 21, y2: 24, bundled: true, imgUrl: null },
  { x1: 0, x2: 3, y1: 48, y2: 51, bundled: true, imgUrl: null },
  { x1: 0, x2: 3, y1: 45, y2: 48, bundled: true, imgUrl: null },
  { x1: 0, x2: 3, y1: 42, y2: 45, bundled: true, imgUrl: null },
  { x1: 0, x2: 3, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: 3, x2: 6, y1: 48, y2: 51, bundled: true, imgUrl: null },
  { x1: 3, x2: 6, y1: 42, y2: 45, bundled: true, imgUrl: null },
  { x1: 3, x2: 6, y1: 39, y2: 42, bundled: true, imgUrl: null },
  { x1: 3, x2: 6, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: 6, x2: 9, y1: 45, y2: 48, bundled: true, imgUrl: null },
  { x1: 6, x2: 9, y1: 42, y2: 45, bundled: true, imgUrl: null },
  { x1: 6, x2: 9, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: 6, x2: 9, y1: 30, y2: 33, bundled: true, imgUrl: null },
  { x1: 6, x2: 9, y1: 24, y2: 27, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: 42, y2: 45, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: 39, y2: 42, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: 36, y2: 39, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: 27, y2: 30, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: 15, y2: 18, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: -15, y2: -12, bundled: true, imgUrl: null },
  { x1: 15, x2: 18, y1: 27, y2: 30, bundled: true, imgUrl: null },
  { x1: 15, x2: 18, y1: 24, y2: 27, bundled: true, imgUrl: null },
  { x1: 15, x2: 18, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: 15, x2: 18, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: 15, x2: 18, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: 18, x2: 21, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: 18, x2: 21, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: 21, x2: 24, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: 21, x2: 24, y1: 30, y2: 33, bundled: true, imgUrl: null },
  { x1: 21, x2: 24, y1: 27, y2: 30, bundled: true, imgUrl: null },
  { x1: 21, x2: 24, y1: 15, y2: 18, bundled: true, imgUrl: null },
  { x1: 21, x2: 24, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: 21, y2: 24, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: 18, y2: 21, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: 15, y2: 18, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -9, y2: -6, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -12, y2: -9, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -15, y2: -12, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: 60, y2: 63, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: 21, y2: 24, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: 18, y2: 21, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: 15, y2: 18, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: 3, y2: 6, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -3, y2: 0, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -6, y2: -3, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -9, y2: -6, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -12, y2: -9, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: 60, y2: 63, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: 21, y2: 24, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: 15, y2: 18, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: 9, y2: 12, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: 6, y2: 9, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: 3, y2: 6, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: 0, y2: 3, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -6, y2: -3, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -9, y2: -6, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -12, y2: -9, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -15, y2: -12, bundled: true, imgUrl: null },
  // { x1: 36, x2: 39, y1: 21, y2: 24, bundled: true, imgUrl: null },
  // { x1: 36, x2: 39, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: 36, x2: 39, y1: -15, y2: -12, bundled: true, imgUrl: null },
  { x1: 39, x2: 42, y1: 48, y2: 51, bundled: true, imgUrl: null },
  // { x1: 39, x2: 42, y1: 21, y2: 24, bundled: true, imgUrl: null },
  // { x1: 39, x2: 42, y1: 18, y2: 21, bundled: true, imgUrl: null },
  // { x1: 39, x2: 42, y1: 15, y2: 18, bundled: true, imgUrl: null },
  // { x1: 39, x2: 42, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: 39, x2: 42, y1: -15, y2: -12, bundled: true, imgUrl: null },
  { x1: 42, x2: 45, y1: 33, y2: 36, bundled: true, imgUrl: null },
  { x1: 42, x2: 45, y1: 30, y2: 33, bundled: true, imgUrl: null },
  { x1: 42, x2: 45, y1: 27, y2: 30, bundled: true, imgUrl: null },
  // { x1: 42, x2: 45, y1: 21, y2: 24, bundled: true, imgUrl: null },
  // { x1: 42, x2: 45, y1: 18, y2: 21, bundled: true, imgUrl: null },
  // { x1: 42, x2: 45, y1: 15, y2: 18, bundled: true, imgUrl: null },
  { x1: 45, x2: 48, y1: 48, y2: 51, bundled: true, imgUrl: null },
  // { x1: 45, x2: 48, y1: 18, y2: 21, bundled: true, imgUrl: null },
  // { x1: 45, x2: 48, y1: 12, y2: 15, bundled: true, imgUrl: null },
  { x1: 45, x2: 48, y1: -15, y2: -12, bundled: true, imgUrl: null },
  { x1: -45, x2: -42, y1: -51, y2: -48, bundled: true, imgUrl: null },
  { x1: -42, x2: -39, y1: -51, y2: -48, bundled: true, imgUrl: null },
  {
    x1: -39,
    x2: -36,
    y1: -24,
    y2: -21,
    bundled: true,
    imgUrl: null,
  },
  { x1: -39, x2: -36, y1: -27, y2: -24, bundled: true, imgUrl: null },
  { x1: -39, x2: -36, y1: -51, y2: -48, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: -27, y2: -24, bundled: true, imgUrl: null },
  { x1: -36, x2: -33, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: -27, y2: -24, bundled: true, imgUrl: null },
  { x1: -33, x2: -30, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: -27, x2: -24, y1: -27, y2: -24, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: -18, y2: -15, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: -27, y2: -24, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: -33, y2: -30, bundled: true, imgUrl: null },
  { x1: -24, x2: -21, y1: -36, y2: -33, bundled: true, imgUrl: null },
  { x1: -21, x2: -18, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: -18, x2: -15, y1: -36, y2: -33, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: -18, y2: -15, bundled: true, imgUrl: null },
  {
    x1: -15,
    x2: -12,
    y1: -27,
    y2: -24,
    bundled: true,
    imgUrl: null,
  },
  { x1: -15, x2: -12, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: -33, y2: -30, bundled: true, imgUrl: null },
  { x1: -15, x2: -12, y1: -36, y2: -33, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: -39, y2: -36, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: -42, y2: -39, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: -45, y2: -42, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: -57, y2: -54, bundled: true, imgUrl: null },
  { x1: -12, x2: -9, y1: -69, y2: -66, bundled: true, imgUrl: null },
  { x1: -9, x2: -6, y1: -39, y2: -36, bundled: true, imgUrl: null },
  { x1: -9, x2: -6, y1: -45, y2: -42, bundled: true, imgUrl: null },
  { x1: -9, x2: -6, y1: -57, y2: -54, bundled: true, imgUrl: null },
  { x1: -9, x2: -6, y1: -60, y2: -57, bundled: true, imgUrl: null },
  { x1: -6, x2: -3, y1: -39, y2: -36, bundled: true, imgUrl: null },
  { x1: -6, x2: -3, y1: -42, y2: -39, bundled: true, imgUrl: null },
  { x1: -6, x2: -3, y1: -45, y2: -42, bundled: true, imgUrl: null },
  { x1: -6, x2: -3, y1: -48, y2: -45, bundled: true, imgUrl: null },
  { x1: -6, x2: -3, y1: -60, y2: -57, bundled: true, imgUrl: null },
  { x1: -3, x2: 0, y1: -39, y2: -36, bundled: true, imgUrl: null },
  { x1: -3, x2: 0, y1: -42, y2: -39, bundled: true, imgUrl: null },
  { x1: -3, x2: 0, y1: -45, y2: -42, bundled: true, imgUrl: null },
  { x1: -3, x2: 0, y1: -57, y2: -54, bundled: true, imgUrl: null },
  { x1: -3, x2: 0, y1: -60, y2: -57, bundled: true, imgUrl: null },
  { x1: 0, x2: 3, y1: -45, y2: -42, bundled: true, imgUrl: null },
  { x1: 0, x2: 3, y1: -57, y2: -54, bundled: true, imgUrl: null },
  { x1: 0, x2: 3, y1: -60, y2: -57, bundled: true, imgUrl: null },
  { x1: 3, x2: 6, y1: -54, y2: -51, bundled: true, imgUrl: null },
  { x1: 3, x2: 6, y1: -57, y2: -54, bundled: true, imgUrl: null },
  { x1: 3, x2: 6, y1: -60, y2: -57, bundled: true, imgUrl: null },
  { x1: 6, x2: 9, y1: -45, y2: -42, bundled: true, imgUrl: null },
  { x1: 6, x2: 9, y1: -54, y2: -51, bundled: true, imgUrl: null },
  { x1: 6, x2: 9, y1: -60, y2: -57, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: -39, y2: -36, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: -51, y2: -48, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: -54, y2: -51, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: -57, y2: -54, bundled: true, imgUrl: null },
  { x1: 9, x2: 12, y1: -60, y2: -57, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: -24, y2: -21, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: -51, y2: -48, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: -54, y2: -51, bundled: true, imgUrl: null },
  { x1: 12, x2: 15, y1: -60, y2: -57, bundled: true, imgUrl: null },
  { x1: 15, x2: 18, y1: -51, y2: -48, bundled: true, imgUrl: null },
  { x1: 15, x2: 18, y1: -54, y2: -51, bundled: true, imgUrl: null },
  { x1: 15, x2: 18, y1: -57, y2: -54, bundled: true, imgUrl: null },
  { x1: 18, x2: 21, y1: -18, y2: -15, bundled: true, imgUrl: null },
  { x1: 18, x2: 21, y1: -21, y2: -18, bundled: true, imgUrl: null },
  { x1: 18, x2: 21, y1: -33, y2: -30, bundled: true, imgUrl: null },
  { x1: 18, x2: 21, y1: -36, y2: -33, bundled: true, imgUrl: null },
  { x1: 21, x2: 24, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: 21, x2: 24, y1: -33, y2: -30, bundled: true, imgUrl: null },
  { x1: 21, x2: 24, y1: -36, y2: -33, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -27, y2: -24, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -33, y2: -30, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -36, y2: -33, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -42, y2: -39, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -45, y2: -42, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -48, y2: -45, bundled: true, imgUrl: null },
  { x1: 24, x2: 27, y1: -63, y2: -60, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: -18, y2: -15, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: -21, y2: -18, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: -33, y2: -30, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: -45, y2: -42, bundled: true, imgUrl: null },
  { x1: 27, x2: 30, y1: -48, y2: -45, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -27, y2: -24, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -30, y2: -27, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -33, y2: -30, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -36, y2: -33, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -39, y2: -36, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -42, y2: -39, bundled: true, imgUrl: null },
  { x1: 30, x2: 33, y1: -48, y2: -45, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -18, y2: -15, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -27, y2: -24, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -33, y2: -30, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -36, y2: -33, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -39, y2: -36, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -42, y2: -39, bundled: true, imgUrl: null },
  { x1: 33, x2: 36, y1: -48, y2: -45, bundled: true, imgUrl: null },
  { x1: 36, x2: 39, y1: -21, y2: -18, bundled: true, imgUrl: null },
  { x1: 36, x2: 39, y1: -24, y2: -21, bundled: true, imgUrl: null },
  { x1: 36, x2: 39, y1: -48, y2: -45, bundled: true, imgUrl: null },
  { x1: 36, x2: 39, y1: -51, y2: -48, bundled: true, imgUrl: null },
  { x1: 39, x2: 42, y1: -24, y2: -21, bundled: true, imgUrl: null },
  { x1: 42, x2: 45, y1: -18, y2: -15, bundled: true, imgUrl: null },
  { x1: 42, x2: 45, y1: -21, y2: -18, bundled: true, imgUrl: null },
  { x1: 42, x2: 45, y1: -24, y2: -21, bundled: true, imgUrl: null },
  { x1: 45, x2: 48, y1: -18, y2: -15, bundled: true, imgUrl: null },
  { x1: 45, x2: 48, y1: -21, y2: -18, bundled: true, imgUrl: null },
  // New Lands
  { x1: 30, x2: 33, y1: 60, y2: 63, bundled: true, imgUrl: null },
  { x1: 36, x2: 39, y1: 66, y2: 69, bundled: true, imgUrl: null },
  { x1: 42, x2: 45, y1: 63, y2: 66, bundled: true, imgUrl: null },
  { x1: 36, x2: 39, y1: 57, y2: 60, bundled: true, imgUrl: null },
  { x1: 36, x2: 39, y1: 54, y2: 57, bundled: true, imgUrl: null },
];

// 6x6 Land
export let lands6x6 = [
  { x1: 12, x2: 18, y1: 60, y2: 66, bundled: true, imgUrl: null },
  { x1: 18, x2: 24, y1: 60, y2: 66, bundled: true, imgUrl: null },
  { x1: 6, x2: 12, y1: 48, y2: 54, bundled: true, imgUrl: null },
  { x1: 36, x2: 42, y1: 30, y2: 36, bundled: true, imgUrl: null },
  { x1: 36, x2: 42, y1: 24, y2: 30, bundled: true, imgUrl: null },
  { x1: 12, x2: 18, y1: 30, y2: 36, bundled: true, imgUrl: null },
  { x1: 0, x2: 6, y1: 30, y2: 36, bundled: true, imgUrl: null },
  { x1: 0, x2: 6, y1: 24, y2: 30, bundled: true, imgUrl: null },
  { x1: 18, x2: 24, y1: 18, y2: 24, bundled: true, imgUrl: null },
  { x1: 12, x2: 18, y1: 0, y2: 6, bundled: true, imgUrl: null },
  { x1: 24, x2: 30, y1: 0, y2: 6, bundled: true, imgUrl: null },
  { x1: -30, x2: -24, y1: 48, y2: 54, bundled: true, imgUrl: null },
  { x1: -18, x2: -12, y1: 48, y2: 54, bundled: true, imgUrl: null },
  { x1: -18, x2: -12, y1: 42, y2: 48, bundled: true, imgUrl: null },
  { x1: -30, x2: -24, y1: 30, y2: 36, bundled: true, imgUrl: null },
  { x1: -6, x2: 0, y1: 30, y2: 36, bundled: true, imgUrl: null },
  { x1: -24, x2: -18, y1: 24, y2: 30, bundled: true, imgUrl: null },
  { x1: -18, x2: -12, y1: 24, y2: 30, bundled: true, imgUrl: null },
  { x1: -36, x2: -30, y1: 12, y2: 18, bundled: true, imgUrl: null },
  { x1: -6, x2: 0, y1: 12, y2: 18, bundled: true, imgUrl: null },
  { x1: -42, x2: -36, y1: -6, y2: 0, bundled: true, imgUrl: null },
  { x1: -42, x2: -36, y1: -12, y2: -6, bundled: true, imgUrl: null },
  { x1: -42, x2: -36, y1: -18, y2: -12, bundled: true, imgUrl: null },
  { x1: -42, x2: -36, y1: -36, y2: -30, bundled: true, imgUrl: null },
  { x1: -36, x2: -30, y1: -36, y2: -30, bundled: true, imgUrl: null },
  { x1: -36, x2: -30, y1: -66, y2: -60, bundled: true, imgUrl: null },
  { x1: -30, x2: -24, y1: -6, y2: 0, bundled: true, imgUrl: null },
  { x1: -30, x2: -24, y1: -36, y2: -30, bundled: true, imgUrl: null },
  {
    x1: -30,
    x2: -24,
    y1: -66,
    y2: -60,
    bundled: true,
    imgUrl: null,
  },
  { x1: -24, x2: -18, y1: -24, y2: -18, bundled: true, imgUrl: null },
  { x1: -18, x2: -12, y1: -66, y2: -60, bundled: true, imgUrl: null },
  { x1: -12, x2: -6, y1: -54, y2: -48, bundled: true, imgUrl: null },
  { x1: -12, x2: -6, y1: -66, y2: -60, bundled: true, imgUrl: null },
  { x1: -6, x2: 0, y1: -54, y2: -48, bundled: true, imgUrl: null },
  { x1: -6, x2: 0, y1: -66, y2: -60, bundled: true, imgUrl: null },
  { x1: 0, x2: 6, y1: -42, y2: -36, bundled: true, imgUrl: null },
  { x1: 0, x2: 6, y1: -66, y2: -60, bundled: true, imgUrl: null },
  { x1: 6, x2: 12, y1: -66, y2: -60, bundled: true, imgUrl: null },
  { x1: 12, x2: 18, y1: -36, y2: -30, bundled: true, imgUrl: null },
  { x1: 18, x2: 24, y1: -54, y2: -48, bundled: true, imgUrl: null },
  { x1: 18, x2: 24, y1: -60, y2: -54, bundled: true, imgUrl: null },
  { x1: 30, x2: 36, y1: -24, y2: -18, bundled: true, imgUrl: null },
  { x1: 36, x2: 42, y1: -30, y2: -24, bundled: true, imgUrl: null },
  { x1: 36, x2: 42, y1: -36, y2: -30, bundled: true, imgUrl: null },
  { x1: 42, x2: 48, y1: -30, y2: -24, bundled: true, imgUrl: null },
  { x1: -12, x2: -6, y1: 12, y2: 18, bundled: true, imgUrl: null },
  { x1: -18, x2: -12, y1: -6, y2: 0, bundled: true, imgUrl: null },
  { x1: -18, x2: -12, y1: -12, y2: -6, bundled: true, imgUrl: null },
  // New Lands
  { x1: 36, x2: 42, y1: 60, y2: 66, bundled: true, imgUrl: null },
];

// 12 x 12 Land
export let lands12x12 = [
  { x1: -48, x2: -36, y1: 36, y2: 48, bundled: true, imgUrl: null },
  { x1: -12, x2: 0, y1: 36, y2: 48, bundled: true, imgUrl: null },
  { x1: 36, x2: 48, y1: 36, y2: 48, bundled: true, imgUrl: null },
  { x1: 24, x2: 36, y1: 24, y2: 36, bundled: true, imgUrl: null },
  { x1: 0, x2: 12, y1: 12, y2: 24, bundled: true, imgUrl: null },
  { x1: -24, x2: -12, y1: 0, y2: 12, bundled: true, imgUrl: null },
  { x1: 12, x2: 24, y1: -12, y2: 0, bundled: true, imgUrl: null },
  {
    x1: -36,
    x2: -24,
    y1: -24,
    y2: -12,
    bundled: true,
    imgUrl: null,
  },
  { x1: -12, x2: 0, y1: -24, y2: -12, bundled: true, imgUrl: null },
  { x1: 0, x2: 12, y1: -24, y2: -12, bundled: true, imgUrl: null },
  { x1: -12, x2: 0, y1: -36, y2: -24, bundled: true, imgUrl: null },
  { x1: 0, x2: 12, y1: -36, y2: -24, bundled: true, imgUrl: null },
  { x1: -48, x2: -36, y1: -48, y2: -36, bundled: true, imgUrl: null },
  { x1: 12, x2: 24, y1: -48, y2: -36, bundled: true, imgUrl: null },
  { x1: 24, x2: 36, y1: -60, y2: -48, bundled: true, imgUrl: null },
  { x1: 36, x2: 48, y1: 12, y2: 24, bundled: true, imgUrl: null },
];

// 24 x 24 Land
export let lands24x24 = [
  { x1: -12, x2: 12, y1: -12, y2: 12, bundled: true, imgUrl: null },
  { x1: -60, x2: -36, y1: 12, y2: 36, bundled: true, imgUrl: null },
  {
    x1: 12,
    x2: 36,
    y1: 36,
    y2: 60,
    bundled: true,
  },
  {
    x1: -36,
    x2: -12,
    y1: -60,
    y2: -36,
    bundled: true,
  },
  { x1: 36, x2: 60, y1: -12, y2: 12, bundled: true, imgUrl: null },
];
