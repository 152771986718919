import * as Configs from "../../config";
import { formatLand } from "../../utils/formatLand";
import { mainLands } from "./lands";

let GRID_COL_LENGTH = 172;
let CELL_SIZE = 4;

const getColor = (
  landType: number,
  isLandInSale: boolean,
  hasOwner: boolean,
  isOwner: boolean
) => {
  if (hasOwner) return "#6e6e6e";
  if (isLandInSale) return "#3c3c3c";
  return "#3c3c3c"; // "#303030";
};

const fillImg = (
  x: number,
  y: number,
  image: any,
  imgSize: number = 1,
  context: any
) => {
  if (!context) return;
  context.beginPath();
  context.drawImage(
    image,
    x * CELL_SIZE,
    y * CELL_SIZE,
    CELL_SIZE * imgSize,
    CELL_SIZE * imgSize
  );
  context.closePath();
};

const fillLand = (
  x: number,
  y: number,
  color: string,
  landSize: number = 1,
  context: any
) => {
  if (!context) return;
  context.beginPath();
  context.fillStyle = color;
  context.fillRect(
    x * CELL_SIZE,
    y * CELL_SIZE,
    CELL_SIZE * landSize,
    CELL_SIZE * landSize
  );
  context.closePath();
};

const fillStroke = (
  x: number,
  y: number,
  strokeColor: string,
  lineWidth: number = 1,
  lineSize: number = 1,
  context: any
) => {
  if (!context) return;
  context.beginPath();
  context.strokeStyle = strokeColor;
  context.lineWidth = lineWidth;
  context.strokeRect(
    x * CELL_SIZE,
    y * CELL_SIZE,
    CELL_SIZE * lineSize,
    CELL_SIZE * lineSize
  );
  context.closePath();
};

const implementLand = (
  land: any,
  landColor: string = "#222",
  size: number = 1,
  lineColor: string = "#000",
  lineWidth: number = 2,
  context: any,
  cord: any,
  length: any
) => {
  let formattedX1 = formatLand(
    land["x1"] - (cord ? cord.x : 0),
    "x",
    length,
    CELL_SIZE
  );
  let formattedY2 = formatLand(
    land["y2"] - (cord ? cord.y3 : 0),
    "y",
    length,
    CELL_SIZE
  );

  fillLand(formattedX1, formattedY2, landColor, size, context);
  fillStroke(formattedX1, formattedY2, lineColor, lineWidth, size, context);
};

const getCameraSize = (type: number) => {
  switch (type) {
    case 1:
      return 2.8;
    case 3:
      return 2.8;
    case 12:
      return 2.9;
    case 24:
      return 2.8;
    default:
      return 2.8;
  }
};

const getGridLength = (type: number) => {
  switch (type) {
    case 1:
      return 172;
    case 3:
      return 164;
    case 6:
      return 152;
    case 12:
      return 128;
    case 24:
      return 80;
    default:
      return 176;
  }
};

const getGridSize = (type: number) => {
  return 108;
};

const getY2 = (type: number) => {
  switch (type) {
    case 1:
      return 0;
    case 3:
    case 6:
    case 12:
    case 24:
      return 0;
    default:
      return 0;
  }
};

const getY3 = (type: number, cord: any) => {
  if (type === 1) return cord.y;
  return cord.y + type - 1;
};

export const generateLandImg = (cord: any, type: number, lands: any) => {
  return new Promise((resolve, reject) => {
    // const canvas = document.getElementById("canvas");
    let canvasThumbnail: any =
      typeof document !== "undefined" && document.createElement("canvas");
    let context = canvasThumbnail && canvasThumbnail.getContext("2d");
    GRID_COL_LENGTH = getGridLength(type);
    let gridSize = getGridSize(type);
    let scale = getCameraSize(type);
    canvasThumbnail.width = 500;
    canvasThumbnail.height = 500;

    let background = new Image();
    background.src = "/assets/Mobile_BG.png";

    background.onload = function () {
      context.drawImage(background, 0, 0);
      context.scale(scale, scale);
      cord["x1"] = 0;
      cord["y2"] = getY2(type);
      cord["y3"] = getY3(type, cord);

      let fromX = cord.x - gridSize;
      let toX = cord.x + gridSize;
      let fromY = cord.y2 - gridSize;
      let toY = cord.y2 + gridSize;
      for (let mainLand of mainLands) {
        for (let row = mainLand["x1"]; row < mainLand["x2"]; row++) {
          for (let col = mainLand["y1"] + 1; col <= mainLand["y2"]; col++) {
            if (row < fromX || row > toX || col < fromY || col > toY) continue;
            let land: any = { x1: row - cord.x, y2: col - cord.y3 };
            implementLand(
              land,
              "#464646",
              1,
              "#000",
              1,
              context,
              null,
              GRID_COL_LENGTH
            );
          }
        }
      }

      // Fill all lands
      let i = 0;
      let imgLen = lands.filter((item: any) => item.img_url).length;
      for (let land of lands) {
        if (land["x"] < fromX && land["x"] + land["type"] > toX) continue;
        if (land["y"] < fromY && land["y"] + land["type"] > toY) continue;

        let isOwner = false;
        const landType = land["type"];
        const isLandSale = land["in_sale"];
        const hasOwner = land["owner"];
        const strokeColor = "#000";
        let color: string = getColor(landType, isLandSale, hasOwner, isOwner);

        if (landType !== 1) {
          land["x1"] = land["x"];
          land["y1"] = land["y"] - 1;
          land["x2"] = land["x"] + land["type"];
          land["y2"] = land["y"] - 1 + land["type"];
          implementLand(
            land,
            color,
            landType,
            strokeColor,
            1,
            context,
            cord,
            GRID_COL_LENGTH
          );
        }

        // Implement selected land
        implementLand(
          cord,
          "#d01b8d",
          type,
          "#000",
          1,
          context,
          null,
          GRID_COL_LENGTH
        );

        if (!land.img_url) continue;
        let formattedX = formatLand(
          land["x1"] - (cord ? cord.x : 0),
          "x",
          GRID_COL_LENGTH,
          CELL_SIZE
        );
        let formattedY = formatLand(
          land["y2"] - (cord ? cord.y3 : 0),
          "y",
          GRID_COL_LENGTH,
          CELL_SIZE
        );
        let imageUrl = land.img_url
          ? `${Configs.API_URL}/images/${land.img_url}`
          : `${Configs.API_URL}/images/${land.tokenId}.jpg`;
        let image = new Image();
        image.src = imageUrl;
        image.crossOrigin = "anonymous";
        fillImg(formattedX, formattedY, image, landType, context);
        fillStroke(
          formattedX,
          formattedY,
          strokeColor,
          1,
          land["type"],
          context
        );

        // eslint-disable-next-line
        image.onload = () => {
          fillImg(formattedX, formattedY, image, landType, context);
          fillStroke(
            formattedX,
            formattedY,
            strokeColor,
            1,
            land["type"],
            context
          );

          // Implement selected land
          implementLand(
            cord,
            "#ff008a",
            type,
            "#000",
            1,
            context,
            null,
            GRID_COL_LENGTH
          );

          i++;
        };
      }

      const interval = setInterval(() => {
        if (i >= imgLen) {
          clearInterval(interval);
          let dataURL = canvasThumbnail && canvasThumbnail.toDataURL();
          return resolve(dataURL);
        }
      }, 1000);
    };
  });
};
