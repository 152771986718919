import axios from "axios";
import * as Configs from "../config";
import { errorHandling, refreshNewToken, setHeader } from "./api";

export class GameStore {
  async betGame(id: string, data: any) {
    const url = `${Configs.API_URL}/v1/prediction/bet/${id}`;
    try {
      const result: any = await axios.post(url, data, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.msg;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getGameDetail(id: string = "") {
    const url = `${Configs.API_URL}/v1/prediction/detail/${id}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getGames(query: string = "") {
    const url = `${Configs.API_URL}/v1/prediction/list/all${query}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }

  async getRecords(query: string = "", address: string) {
    const url = `${Configs.API_URL}/v1/prediction/transactions/${address}${query}`;
    try {
      const result: any = await axios.get(url, setHeader());
      if (!result || !result.data?.success) return false;
      refreshNewToken(result);
      return result?.data?.data;
    } catch (e) {
      return errorHandling(e);
    }
  }
}

export const STORE_GAME = "gameStore";
