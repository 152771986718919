import { Modal } from "antd";
import { capitalised } from "src/utils";
import { CloseButton } from "./close-btn";

export const ModalConfirmBet = (props: any) => {
  const { isVisible, setIsVisible, confirmBet, bet, side } = props;

  const submit = async () => {
    confirmBet();
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      title={`Confirm To Predict`}
      visible={isVisible}
      footer={null}
      centered={true}
    >
      {/* Close btn */}
      <CloseButton handleCancel={() => handleCancel()} type={"black"} />

      <div className="my-[30px] flex flex-col justify-start align-start text-left">
        {/* Notes */}
        <div className="">
          <p className="uppercase text-[12px]">
            Please confirm the following action
          </p>
          <ul className="mt-[20px] list-none">
            <li className="text-black">
              <span className={`${$labelStyle}`}>Prediction</span>
              <span className="text-[14px] txt-dark-gradient">
                {bet} Giga Credit
              </span>
            </li>
            <li className="text-black">
              <span className={`${$labelStyle}`}>Side</span>
              <span
                className={`text-[14px]  ${
                  side === "long" ? "text-green-500" : "text-red-500"
                }`}
              >
                {capitalised(side)}
              </span>
            </li>
          </ul>
        </div>
        {/* Confirm Button */}
        <div className="flex-1 flex flex-col justify-end pt-[20px]">
          <button className="mt-[20px] text" onClick={() => submit()}>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

const $labelStyle = "min-w-[100px] inline-block text-[12px]";
