import React from "react";
import { ReactComponent as CloseIcon } from "../button/icons/btn_11_close.svg";

export const CloseButton = (props: any) => {
  const { handleCancel, type, position = "absolute" } = props;

  return (
    <div
      className={`${
        position === "absolute" && "absolute top-[30px] right-[30px]"
      } z-10 w-[44px] h-[44px] cursor-pointer close-icon close-icon-${type}`}
      onClick={() => handleCancel()}
    >
      <CloseIcon />
    </div>
  );
};
