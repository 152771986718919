import React, { useState } from "react";
import { ComingSoonModal } from "../coming-soon-modal";
import { Footer } from "../footer";
import { Header } from "../header";
import { Trailer } from "./trailer";

export const Container = (props: any) => {
  const { children, page, containerStyle = "" } = props;
  const [showTrailer, setShowTrailer] = useState<boolean>(false);
  const [showComingSoonModal, setShowComingSoonModal] =
    useState<boolean>(false);

  return (
    <div className={`min-h-[100vh] ${containerStyle}`}>
      <Header
        setShowTrailer={setShowTrailer}
        setShowComingSoonModal={setShowComingSoonModal}
      />
      {children}

      {page === "home" && (
        <Footer
          setShowTrailer={setShowTrailer}
          setShowComingSoonModal={setShowComingSoonModal}
        />
      )}

      {/* Trailer */}
      {showTrailer && <Trailer setShowTrailer={setShowTrailer} />}

      {/* Coming Soon Modal */}
      {showComingSoonModal && (
        <ComingSoonModal
          isModalOpen={showComingSoonModal}
          setIsModalOpen={setShowComingSoonModal}
        />
      )}
    </div>
  );
};
