import { message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Container, FullPageLoading } from "src/components";
import { useMapStore } from "src/stores";
import { getLandTypeName } from "src/utils";
import { useAccount } from "wagmi";
import { EditLandModal } from "./edit-land-modal";
import { License } from "./license";
import { SelectNft } from "./select-nft";
import { ReactComponent as SearchIcon } from "../../components/button/icons/btn_07_search.svg";

const inputClass =
  "px-5 py-[10px] pl-[40px] bg-black border text-[#d8d8d8] text-[12px] border-[1px] shadow-sm border-[#505050] placeholder:text-[12px] placeholder:!text-[#505050] rounded-[15px] hover:outline-none hover:border-[#d8d8d8] block w-full rounded-md lg:text-sm duration-300 transition-all focus:outline-none focus:border-[#d8d8d8]";

export const LandLicenses = (props: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { getLands } = useMapStore();
  const [licenses, setLicenses] = useState([]);
  const [license, setLicense] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState("all");
  const [query, setQuery] = useState<string | null>(null);
  const [openEditLandModal, setOpenEditLandModal] = useState<boolean>(false);

  const { address } = useAccount();

  useEffect(() => {
    if (!address) return;
    setLoading(true);
    getLands(`owner=${address}`).then((result) => {
      setLoading(false);
      if (!result) return;
      setLicenses(result);
    });
  }, [getLands, address]);

  const filteredLicenses = useMemo(() => {
    if (!licenses || licenses.length === 0) return [];
    if (!query) return licenses;
    return licenses.filter(
      (item: any) =>
        (item.x && item.x.indexOf(query) > -1) ||
        (item.y && item.y.indexOf(query) > -1) ||
        (item.land_name && item.land_name.toLowerCase()?.includes(query)) ||
        (item.land_desc && item.land_desc.toLowerCase()?.includes(query)) ||
        (item.type &&
          getLandTypeName(parseFloat(item.type))
            ?.toLowerCase()
            ?.includes(query))
    );
  }, [licenses, query]);

  const handleQuery = (e: any) => {
    const value = e.target.value.toLowerCase();
    if (!value) return setQuery(null);
    return setQuery(value);
  };

  return (
    <Container>
      {contextHolder}

      {loading && <FullPageLoading />}
      {!loading && (
        <div className="pt-28 px-[30px] relative">
          <h2 className="uppercase">Land Licenses</h2>
          <SelectNft tab={tab} setTab={setTab} />

          {/* Search bar */}
          <div className="pt-[25px] relative">
            <input
              placeholder="SEARCH ITEMS"
              className={inputClass}
              onChange={handleQuery}
            />
            <div className="search-icon absolute top-[34px] left-[13px]">
              <SearchIcon />
            </div>
          </div>

          {/* Not Login */}
          {!address && (
            <div className="text-center mt-20">
              <p className="uppercase">Please first connect to your wallet</p>
            </div>
          )}

          {/* Licenses not found */}
          {address && (!licenses || licenses.length === 0) && (
            <div className="text-center mt-20">
              <p className="uppercase">You do not have any license yet</p>
            </div>
          )}

          {/* Grid of licenses */}
          {address && filteredLicenses && filteredLicenses.length > 0 && (
            <div className="mt-[20px]">
              <div className="flex flex-row items-center w-[100%]">
                <div className="flex flex-row flex-1 items-center">
                  <p className="uppercase overflow-hidden text-[12px] text-ellipsis max-w-[200px]">
                    {address}
                  </p>
                  <div
                    className="w-[20px] h-[20px] overflow-hidden cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(address);
                      messageApi.success("Address is copied!");
                    }}
                  >
                    <img
                      src="/assets/icons-white/btn_12_copy.svg"
                      alt="copy-icon"
                      className="ml-10px w-[28px] h-[28px] relative top-[-4px]"
                    />
                  </div>
                </div>

                {/* Items */}
                <p className="text-[15px] uppercase flex">
                  {licenses.length} Items
                </p>
              </div>
              <div className="mt-[20px] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-[15px]">
                {filteredLicenses.map((item, i) => {
                  return (
                    <div key={i}>
                      <License
                        land={item}
                        setLicense={setLicense}
                        setOpenEditLandModal={setOpenEditLandModal}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}

      <EditLandModal
        isModalOpen={openEditLandModal}
        setIsModalOpen={setOpenEditLandModal}
        land={license}
        messageApi={messageApi}
        setLicenses={setLicenses}
        getLands={getLands}
        setLoading={setLoading}
        address={address}
      />
    </Container>
  );
};
