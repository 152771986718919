import React from "react";
import { ReactComponent as MapIcon } from "./icons/btn_02_map.svg";
import { ReactComponent as MetaverseIcon } from "./icons/btn_01_metaverse.svg";
import { ReactComponent as MetamaskIcon } from "./icons/btn_29_metamask.svg";
import { ReactComponent as MenuIcon } from "./icons/btn_13_meun.svg";
import { ReactComponent as TrailerIcon } from "./icons/btn_03_trailer.svg";
import { ReactComponent as OpenseaIcon } from "./icons/btn_04_opensea.svg";
import { ReactComponent as LogoutIcon } from "./icons/btn_05_logout.svg";
import { ReactComponent as Profile } from "./icons/btn_06_profile.svg";
import { ReactComponent as MooarIcon } from "./icons/btn_33_mooar.svg";
import { ReactComponent as AirdropIcon } from "../button/icons/btn_35_airdrop.svg";

export const Button = (props: any) => {
  const { icon, action } = props;

  const getIconName = () => {
    if (icon === "airdrop") return "Airdrop";
    if (icon === "map") return "Map";
    if (icon === "metaverse") return "Game";
    if (icon === "logout") return "Logout";
    if (icon === "metamask") return "Wallet";
    if (icon === "trailer") return "Trailer";
    if (icon === "opensea") return "Opensea";
    if (icon === "mooar") return "Mooar";
  };

  const isSmallMenu = (icon: string) => {
    return icon === "menu" || icon === "profile";
  };

  return (
    <button
      className={`${isSmallMenu(icon) ? "small-icon" : "normal"}`}
      onClick={action}
    >
      {!isSmallMenu(icon) && (
        <>
          <p className="flex flex-1 justify-center">{getIconName()}</p>

          <div className={`${icon === "metamask" ? "normal" : "transform"}`}>
            {icon === "airdrop" && <AirdropIcon />}
            {icon === "map" && <MapIcon />}
            {icon === "metaverse" && <MetaverseIcon />}
            {icon === "metamask" && <MetamaskIcon />}
            {icon === "trailer" && <TrailerIcon />}
            {icon === "opensea" && <OpenseaIcon />}
            {icon === "mooar" && <MooarIcon />}
            {icon === "logout" && <LogoutIcon />}
          </div>
        </>
      )}

      {icon === "profile" && (
        <div>
          <Profile />
        </div>
      )}

      {icon === "menu" && (
        <div>
          <MenuIcon />
        </div>
      )}
    </button>
  );
};
