import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { useGameStore } from "src/stores";
import { GameBlock } from "src/components/game-block";
import { LoadingIcon } from "src/components/loading-icon";

export const Games = (props: any) => {
  const [games, setGames] = useState<any>(null); // Default: null
  const [loading, setLoading] = useState<boolean>(false);

  const { getGames } = useGameStore();
  const { address } = useAccount();

  const getGamesCb = useCallback(
    async (query = "") => {
      const result = await getGames(query);
      if (!result) return;
      setGames(result);
    },
    [getGames]
  );

  useEffect(() => {
    getGamesCb();
  }, [getGamesCb]);

  useEffect(() => {
    if (!address) return setLoading(false);
    if (address) {
      setLoading(true);
      if (!games) return;
      setLoading(false);
    }
  }, [address, games]);

  return (
    <div className="mt-[30px] overflow-x-hidden">
      <div className="flex flex-row items-center">
        <h2 className="flex-1 text-black">All Games</h2>
      </div>
      {/* Game Blocks */}
      {games && (
        <>
          {/* Game Blocks */}
          <div className="pt-[20px]">
            {games.length > 0 && (
              <div className="flex flex-row flex-wrap justify-start">
                {games.map((item: any, i: number) => {
                  return <GameBlock key={i} game={item} />;
                })}
              </div>
            )}

            {games.length === 0 && (
              <p className="text-center text-black">
                No game available at the moment
              </p>
            )}
          </div>
        </>
      )}
      {loading && (
        <div className="flex justify-center items-center pt-[20px]">
          <LoadingIcon color="#000" />
        </div>
      )}
    </div>
  );
};
