import React from "react";
import { Carousel } from "antd";
import { API_URL } from "src/config";
import { LoadingIcon } from "../loading-icon";

const LAND_IMG = `w-full h-full object-cover`;

export const LandlCarousel = (props: any) => {
  const { land = {}, landImg, loading } = props;
  return (
    <div className="w-[full] h-[full] lg:w-[364] lg:h-[364] rounded-[10px] overflow-hidden">
      <Carousel autoplay>
        {/* Loading Icon */}
        {loading && (
          <div className="w-full h-full justify-center items-center bg-black/0 pt-[35%]">
            <LoadingIcon size={60} />
          </div>
        )}

        {landImg && (
          <div>
            <img src={landImg} alt="land-img" className={LAND_IMG} />
          </div>
        )}

        {!land.tokenId && (
          <div>
            <img
              src={`/assets/Land.jpg`}
              alt="default-lang"
              className={LAND_IMG}
            />
          </div>
        )}

        {/* First Featured Image */}
        {land && land.tokenId && (
          <div>
            <img
              src={`${API_URL}/images/${land.tokenId}.jpg`}
              alt="lang-img"
              className={LAND_IMG}
            />
          </div>
        )}

        {/* Logo */}
        {land && land.img_url && (
          <div>
            <img
              src={`${API_URL}/images/${land.img_url}`}
              alt="lang-img"
              className={LAND_IMG}
            />
          </div>
        )}

        {/* Preview Images */}
        {land &&
          land.previewImgs &&
          land.previewImgs.length > 0 &&
          land.previewImgs.map((previewImg: any, i: number) => {
            return (
              <div key={i}>
                <img
                  src={`${API_URL}/images/${previewImg.img_url}`}
                  alt="lang-img"
                  className={LAND_IMG}
                />
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};
