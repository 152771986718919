import React, { useMemo } from "react";
import { ImageOverlay, Rectangle } from "react-leaflet";
import { LatLngBounds } from "leaflet";
import { useAccount } from "wagmi";

export const Land = (props: any) => {
  const {
    land,
    zoomLevel,
    setShowLandDetail,
    setLand,
    loading,
    messageApi,
    setLandImg,
  } = props;
  const { address } = useAccount();

  const type = land["type"];
  // if (type !== 24) return;
  const x1 = land["x"];
  const y1 = land["y"];
  const x2 = (x1 + type).toFixed(0);
  const y2 = (y1 + type).toFixed(0);
  const bounds: any = [
    [y1, x1],
    [y2 - 1, x1], // -1 fitting the background
    [y2 - 1, x2], // -1 fitting the background
    [y1 - 1, x2], // -1 fitting the background
  ];
  const img = land["img_url"];
  const isLandSale = land["in_sale"];
  const hasOwner = land["owner"];
  let isOwner = (address && land["owner"] === address) || false;
  // let isSelected =
  //   selectedLand["x"] === land["x"] && selectedLand["y"] === land["y"];

  const lineWidth = useMemo(() => {
    // if (!showMap) return 0;
    if (zoomLevel > 4) return 2;
    if (zoomLevel > 2) return 1;
    return 0.5;
  }, [zoomLevel]);

  const showImage = useMemo(() => {
    if (zoomLevel < 4 && type < 3) return false;
    return img;
  }, [zoomLevel, img, type]);

  const getLandColor = (
    landType: number,
    isLandInSale: boolean,
    hasOwner: boolean,
    isOwner: boolean
  ) => {
    if (isOwner) return "#1ffff3";
    if (hasOwner) return "#6e6e6e";
    if (isLandInSale) return "#3c3c3c";
    return "#3c3c3c"; // "#303030";
  };

  const fillColor = showImage
    ? "rgba(0,0,0,0)"
    : getLandColor(type, isLandSale, hasOwner, isOwner);

  return (
    <Rectangle
      bounds={new LatLngBounds(bounds)}
      pathOptions={{
        color: "#000",
        weight: lineWidth,
        fill: true,
        fillColor: fillColor,
        fillOpacity: 1,
      }}
      eventHandlers={{
        click: () => {
          if (loading)
            return messageApi.warning(
              "Fetching Land License Image...Please wait a second..."
            );
          setLand({ ...land, x1, x2, y1, y2 });
          setShowLandDetail(true);
          setLandImg(null);
        },
      }}
    >
      {/* {popUp(x1, x2, y1, y2)} */}
      {showImage && (
        <ImageOverlay
          url={`https://api.gigaspace.io/images/${img}`}
          bounds={new LatLngBounds(bounds)}
          opacity={1}
          zIndex={10}
        />
      )}
    </Rectangle>
  );
};
